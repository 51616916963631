@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --blue-5: #F3F6FD;
    --blue-10: #E4F1FC;
    --blue-20: #B1C0F3;
    --blue-30: #CDE6F9;
    --blue-40: #C3E1F8;
    --blue-50: #EEF4FC;
    --blue-60: #95C9F3;
    --blue-100: #3B61E1;
    --blue-120: #1678C5;
    --blue-170: #08528C;
    --blue-400: #89A0ED;
    --blue-500: #1B35AB;

    --red-5: #FFEFEC;
    --red-10: #FFE1DB;
    --red-20: #FFCFC7;
    --red-100: #E97E63;
    --red-30: #FFB0A4;
    --red-50: #FFEFEC;
    --red-170-40: rgba(159, 58, 30, 0.4);
    --red-170: #9F3A1E;
    --red-500: #DB5249;

    --yellow-30: #FBE4B6;
    --yellow-50: #FBEFE0;
    --yellow-400: #E7A85A;
    --yellow-100: #F3AB1B;
    --yellow-170-40: rgba(153, 102, 0, 0.4);
    --yellow-170: #996600;

    --green-30: #C4EDCE;
    --green-50: #EAF7EB;
    --green-100: #2FB163;
    --green-400: #49AF62;
    --green-500: #1a9a48;
    --green-170-40: rgba(40, 113, 69, 0.4);
    --green-170: #287145;

    --pink-30: #FFEFF4;
    --pink-120: #D95A89;

    --purple-100: #7147C2;
    --purple-30: #FAEEFB;
    --purple-60: #AC94DB;
    --purple-120: #A053AE;

    --orange-100: rgb(243, 110, 27);
    --orange-30: #FFE0CC;
    --orange-60: #F9B58B;
    --orange-120: #DA5B0B;
    --orange-170: #c45511;

    --black-100: #212121;
    --white-100: #FFFFFF;
    --white-100-20: rgba(255, 255, 255, 0.2);
    --white-100-50: rgba(255, 255, 255, 0.5);
    --black-5: #F7F7F7;
    --black-10: #E5E5E5;
    --black-15: #DEDEDE;
    --black-30: #BFBFBF;
    --black-50: #A1A1A1;
    --black-60: #8A8A8A;
    --black-70: #666666;

    --menu-size: 13.75rem;

    --primary-font: 'Manrope', sans-serif;
    --secondary-font: 'Geomanist', sans-serif;
}

* {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

body {
    font-family: var(--primary-font), serif !important;
    background-color: var(--white-100);
    overflow-x: hidden;
}

.input {
    height: 2.5rem;
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--black-15);
    padding: 0 1.25rem 0 2.5rem;
    position: relative;
}

input:focus-visible,
textarea:focus-visible {
    outline: transparent;
}

.main-router-content {
    height: calc(100vh - 5rem);
    overflow-y: scroll;
    overflow-x: hidden;
}

.input-date-container {
    display: flex;
}

.view-container .input-date-container .waiting-order-warning {
    margin-bottom: 0;
}

.portal-content,
.test-content {
    height: calc(100vh - 6.25rem);
}

.input-container:before,
.input-container-search:before,
.input-address-container:before {
    content: '';
    position: absolute;
    left: 0.75rem;
    top: 0.6875rem;
    width: 1.125rem;
    height: 1.375rem;
    background: url('../icons/search.svg') no-repeat;
    background-size: contain;
    z-index: 1;
}

.list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem;
}

.view-container .waiting-order-warning.no-margin-bottom {
    margin-bottom: 0;
}

.without-top {
    padding-top: 0;
}

.header-input {
    width: 100%;
}

.header-input input {
    font-size: 2em;
    font-weight: 700;
    border: 0;
    width: 100%;
    padding: 0;
    height: 2.5rem;
}

.header-input input::placeholder {
    color: var(--black-15);
}

.header-title h1 {
    font-size: 1.8em;
    font-weight: 700;
    padding-left: 3.125rem;
    position: relative;
    margin-bottom: 0;
    width: max-content;
}

.header-title.is-task h1 {
    padding-left: 0;
    position: initial;
}

.header-title h1:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.875rem;
    height: 1.5rem;
    background: url('../icons/folder-open--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.header-title.settings h1:before {
    left: 0;
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/settings.svg') no-repeat;
    background-size: contain;
}

.header-title.customers h1:before {
    width: 2.375rem;
    height: 2.375rem;
    background: url('../icons/customers--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.comptabilite h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/coins--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.pennylane h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/pennylane.svg') no-repeat;
    background-size: contain;
}

.header-title.inventaire h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/inventaire--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.metas h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/globe--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.projects h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/dashboard--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.user h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/users--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.roles h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/key--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.holidays h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/umbrella-beach--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.tests h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/briefcase--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.orders h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/order--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.providers h1:before {
    width: 2.25rem;
    height: 2.25rem;
    background: url('../icons/supplier--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.souscription h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/chatbot--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.clients h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/clients--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.fournisseurs h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/fournisseurs--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.groupes h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/groupes--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.macro h1:before {
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/coins--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.none h1 {
    padding: 0;
}

.header-title.none h1:before {
    content: none;
}

.header-title h2 {
    font-size: 1.6em;
    font-weight: 800;
    padding-left: 3.125rem;
    position: relative;
    margin-bottom: 0;
}

.header-title h2:before {
    content: '';
    position: absolute;
    left: 0.125rem;
    top: 0.3125rem;
    width: 1.5rem;
    height: 1.5rem;
    background: url('../icons/folder-open--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.header-title.users h2:before {
    left: 0.1875rem;
    top: 0.1875rem;
    width: 1.5rem;
    height: 1.5rem;
    background: url('../icons/account--white.svg') no-repeat;
    background-size: contain;
}

.header-title img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    object-fit: cover;
    aspect-ratio: 1/1;
    border: 1px solid var(--black-10);
}

.header-title .icon {
    width: 2.1875rem;
    height: 2.1875rem;
    border-radius: 0.5rem;
    background-color: var(--black-5);
    border: 0.0625rem solid var(--black-10);
    display: flex;
    align-items: center;
    justify-content: center;
}

.macro-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.macro-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.macro-content {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
}

.billing-input {
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--black-30);
    width: 6.25rem;
    padding: 0 0.625rem;
    height: 1.75rem;
}

.billing-metas-amount {
    display: flex;
    align-items: center;
}

.billing-metas-amount .custom-tag {
    padding: 0.0625rem 0.375rem;
    font-size: .9em;
    margin-left: 0.375rem;
}

.main-button,
.small-button {
    color: var(--black-100);
    background-color: var(--white-100);
    border: 0.0625rem solid var(--black-15);
    height: 2.375rem;
    padding: 0.625rem 0.875rem;
    font-weight: 500;
    border-radius: 0.5rem;
    position: relative;
    transition: all .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: fit-content;
    gap: 0.5rem;
    white-space: nowrap;
    text-wrap: nowrap;
}

.main-button:disabled {
    background: var(--black-5);
    color: var(--black-30);
}

.main-button:disabled .MuiCircularProgress-svg {
    color: var(--black-30);
}

.squared-button {
    background-color: var(--white-100);
    border: 0.0625rem solid var(--black-15);
    height: 2.375rem;
    width: 2.375rem;
    font-weight: 500;
    border-radius: 0.5rem;
    position: relative;
    transition: all .3s;
}

.squared-button:hover {
    background: var(--black-5);
}

.input-container .squared-button {
    margin-left: 0.625rem;
}

.squared-button.blue-button {
    background: var(--blue-100);
}

.squared-button.blue-button:hover {
    background: var(--blue-500);
}

.squared-button.blue-button:before {
    width: 1.0625rem;
    top: 0.5625rem;
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
}

.squared-button:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1.125rem;
    height: 0.25rem;
    background: url('../icons/options--gray.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.main-button.warning-button {
    border: 0.0625rem solid var(--red-30);
    color: var(--red-500);
}

.main-button.warning-button:hover {
    border: 0.0625rem solid var(--red-100);
    background: var(--red-100);
    color: var(--white-100);
}

.main-button.without-icon {
    padding: 0 1.25rem;
}

.main-button.without-icon:before {
    display: none;
}

.input-group {
    padding: 1.875rem;
}

.input-group.without-top {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.input-label {
    width: 12.5rem;
    padding-left: 2.1875rem;
    position: relative;
    text-wrap: nowrap;
}

.input-label.flex-width {
    width: fit-content;
    margin-right: 1.25rem;
}

.input-label.small-input {
    min-width: 9.0625rem;
    width: fit-content;
    font-size: 15px;
    font-weight: 500;
}

.input-label.medium-input {
    min-width: 10rem;
    width: fit-content;
}

.input-label.large-input {
    min-width: 13.4375rem;
    width: fit-content;
}

.input-container.transaction-mapper,
.single-search-input.invoice-search-input {
    width: calc(100% - 1rem);
}

.single-search-input.invoice-search-input.error-input {
    width: 100%;
}

.single-search-input.invoice-search-input .select__control {
    min-height: 2.375rem;
}

.input-container.transaction-mapper:before {
    top: 0.625rem;
}

.input-container,
.input-container-search {
    width: calc(100% - 12.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.input-container.switch {
    justify-content: flex-start;
}

.input-container input,
.input-container-search input {
    width: 100%;
}

.input-container.without-icon:before {
    display: none;
}

.input-label {
    font-weight: 500;
}

.input-label:before {
    content: '';
    position: absolute;
    left: 0.9375rem;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 16px;
    width: 16px;
    background: url('../icons/customers--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.large:before {
    width: 20px;
    height: 20px;
}

.input-label.user:before {
    background: url('../icons/account--white.svg') no-repeat;
    background-size: contain;
}

.input-label.provider:before {
    background: url('../icons/supplier--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.file:before {
    background: url('../icons/link-alt--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.file-black:before {
    background: url('../icons/link-alt--black.svg') no-repeat;
    background-size: contain;
}

.input-label.phone:before {
    background: url('../icons/phone--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.location:before {
    background: url('../icons/map-pin--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.reminder:before {
    background: url('../icons/reminder--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.order:before {
    background: url('../icons/order--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.type:before {
    background: url('../icons/squares-four--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.euro:before {
    background: url('../icons/euro--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.clock:before {
    background: url('../icons/clock--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.id:before {
    background: url('../icons/id--gray.svg') no-repeat;
    background-size: contain;
}

.input-label.link:before {
    background: url('../icons/link-alt--gray.svg') no-repeat;
    background-size: contain;
}

.input-label.link-blue:before {
    background: url('../icons/link-alt--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.token:before {
    background: url('../icons/broadcast--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.engine:before {
    background: url('../icons/globe--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.email:before {
    background: url('../icons/message--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.email-black:before {
    background: url('../icons/message--black.svg') no-repeat;
    background-size: contain;
}

.input-label.role:before {
    background: url('../icons/role--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.calendar:before {
    background: url('../icons/calendar--black.svg') no-repeat;
    background-size: contain;
}

.input-label.calendar-blue:before {
    background: url('../icons/calendar--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.pen:before {
    background: url('../icons/pen--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.folder:before {
    background: url('../icons/folder-open--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.statut:before {
    background: url('../icons/tag-simple--gray.svg') no-repeat;
    background-size: contain;
}

.input-label.statut-blue:before {
    background: url('../icons/tag-simple--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.groupe:before {
    background: url('../icons/building--black.svg') no-repeat;
    background-size: contain;
}

.input-label.doc:before {
    background: url('../icons/document--black.svg') no-repeat;
    background-size: contain;
}

.input-label.support:before {
    background: url('../icons/wrench--gray.svg') no-repeat;
    background-size: contain;
}

.input-label.color:before {
    background: url('../icons/palette--blue.svg') no-repeat;
    background-size: contain;
}

.input-label.time:before {
    background: url('../icons/consommation--black.svg') no-repeat;
    background-size: contain;
}

.input-row-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.input-row-container.align-columns {
    flex-direction: column;
    align-items: flex-start;
}

.input-row-container.align-columns .input-container {
    width: 100%;
}

.input-row-container.align-columns .input-label {
    margin-bottom: 0.9375rem;
}

.input-textarea-container,
.input-orders-container {
    width: 100%;
    margin-bottom: 2.5rem;
}

.input-orders-container .list-container {
    margin-top: 1.25rem;
    height: unset;
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--black-5);
}

.input-orders-container .list-container tbody tr td:first-child {
    padding-left: 1.875rem;
}

.input-orders-container .list-container thead th:first-child {
    padding-left: 3.375rem;
}

.input-orders-container .list-container .column-icon:first-child:before {
    left: 1.875rem;
}

.input-textarea-container:last-child {
    margin-bottom: 0;
}

.list-container tbody tr:last-child {
    border-bottom: 0;
}

.input-textarea textarea,
.textarea-view {
    width: 100%;
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--black-15);
    padding: 0.625rem 0.9375rem;
    position: relative;
    margin-top: 1.25rem;
    resize: none;
}

.textarea-view {
    min-height: 10rem;
    white-space: pre-line;
}

.main-button:hover,
.small-button:hover,
.mapper-button:hover {
    border: 0.0625rem solid var(--black-10);
    background: var(--black-5);
}

.main-button.blue-button {
    background: var(--blue-100);
    color: var(--white-100);
    border: none;
}

.mapper-button {
    background: var(--blue-100);
    color: var(--white-100);
    font-weight: 600;
    height: 1.75rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    padding-left: 1.625rem;
    transition: all .3s;
    border: 0.0625rem solid transparent;
    position: relative;
}

.mapper-button:hover:before {
    background: url('../icons/plus-blue.svg') no-repeat;
    background-size: contain;
}

.mapper-button:before {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
}

.main-button.green-button {
    background: var(--green-100);
    border: 0;
    color: var(--white-100);
    font-weight: 600;
}

.main-button.green-button:hover {
    background: var(--green-170);
}

.main-button.red-button:hover {
    background: var(--red-170);
}

.main-button.red-button {
    background: var(--red-100);
    border: 0;
    color: var(--white-100);
    font-weight: 600;
}

.main-button.blue-button:disabled,
.main-button.blue-button:disabled:hover {
    background: var(--blue-20);
}

.main-button.blue-button:hover {
    background: var(--blue-500);
}

.main-button.blue-button:before {
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
    width: 1.125rem;
    left: 1.0625rem;
    transition: all .3s;
}

.main-button.blue-button.launch:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
    width: 1.25rem;
}

.main-button.red-button.close-button:before {
    background: url('../icons/close--white.svg') no-repeat;
    background-size: contain;
    width: 0.9375rem;
    left: 1.125rem;
    top: 0.8125rem;
}

.main-button.blue-button.launch:hover:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
}

.edit-button {
    display: flex;
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.3125rem;
    border-radius: 0.625rem;
    background-color: var(--white-100);
    border: 0.0625rem solid var(--black-10);
    transition: all 0.3s ease-in-out;
    font-weight: 600;
}

.edit-button:hover {
    background-color: var(--black-10);
}

.edit-button:before {
    content: '';
    background: url('../icons/pen--black.svg') no-repeat;
    background-size: contain;
    width: 0.9375rem;
    height: 0.9375rem;
}

.edit-button:hover:before {
    background: url('../icons/pen--black.svg') no-repeat;
    background-size: contain;
}

/* .main-button.blue-button.validate:before,
.main-button.green-button.validate:before {
    background: url('../icons/check--white.svg') no-repeat;
    background-size: contain;
    width: 1.0625rem;
    left: 1.125rem;
    top: 0.9375rem;
}

.main-button.blue-button.validate:hover:before,
.main-button.green-button.validate:hover:before {
    background: url('../icons/check--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button:hover:before {
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
}

.main-button:hover:before,
.small-button:hover:before {
    background: url('../icons/filter-blue.svg') no-repeat;
    background-size: contain;
}

.main-button.download:hover:before {
    background: url('../icons/download-blue.svg') no-repeat;
    background-size: contain;
}

.main-button:before,
.small-button:before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 0.6875rem;
    width: 1.0625rem;
    height: 1.375rem;
    background: url('../icons/filter.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.main-button.download:before {
    background: url('../icons/download--gray.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.download:before {
    background: url('../icons/download--white.svg') no-repeat;
    background-size: contain;
    height: 1.125rem;
}

.main-button.validate:before {
    background: url('../icons/check--black.svg') no-repeat;
    background-size: contain;
    top: 0.875rem;
}

.main-button.validate:hover:before {
    background: url('../icons/check--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
}

.main-button.refresh:before {
    background: url('../icons/refresh--black.svg') no-repeat;
    background-size: contain;
}

.main-button.refresh:hover:before {
    background: url('../icons/refresh--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.main-button.archive:before {
    background: url('../icons/archive--black.svg') no-repeat;
    background-size: contain;
    width: 1.3125rem;
}

.main-button.archive:hover:before {
    background: url('../icons/archive--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.small-button.download:before {
    top: 0.5625rem;
    left: 0.6875rem;
} */

.search-list {
    position: absolute;
    background-color: var(--white-100);
    width: 100%;
    top: 2.5rem;
    border-radius: 0 0 0.75rem 0.75rem;
    border: 0.0625rem solid var(--black-15);
    border-top: 0;
    max-height: 19.125rem;
    overflow: hidden;
}

.search-list.hidden {
    display: none;
}

.input-container-search input:focus {
    border-radius: 0.75rem 0.75rem 0 0;
}

.search-list span {
    font-size: 0.8em;
    padding: 0.625rem;
    display: block;
    font-weight: 500;
    color: var(--black-70);
}

.custom-tag,
.single-search-input .select__single-value,
.multi-search-input .select__multi-value {
    font-weight: 600;
    color: var(--blue-100);
    background-color: var(--blue-10);
    padding: 0.125rem 0.5625rem;
    width: fit-content;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin: 0;
    font-size: 14px;
}

.categories-data-container {
    margin-top: 0.625rem;
    font-size: .9em;
}

.project-view .categories-data-container {
    font-size: 1em;
}

.project-view .budget-container.main-budget {
    margin-bottom: 1.875rem;
}

.categories-data-container .budget-container .budget-progress {
    height: 1.125rem;
}

.categories-data-container .budget-container.not-estimated .total-cost-badge {
    color: var(--orange-170);
}

.categories-data-container .budget-container.not-estimated .budget-progress {
    border: 0.0625rem solid var(--orange-30);
}

.project-card .budget-container.not-estimated .budget-progress .MuiLinearProgress-bar {
    background: var(--orange-30);
}

.categories-data-container .total-cost-badge {
    height: 1.125rem;
}

.custom-tag.failed,
.invoice-option-select.failed,
.custom-tag.unpaid,
.custom-tag.refused,
.custom-tag.no-assistance {
    background: var(--red-30);
    color: var(--red-170);
}

.custom-tag.manual,
.custom-tag.waiting,
.custom-tag.essential {
    background: var(--orange-30);
    color: var(--orange-120);
}

.custom-tag.uppercase {
    text-transform: uppercase;
}

.custom-tag.ignore,
.custom-tag.paid,
.custom-tag.accepte,
.custom-tag.success {
    background: var(--green-30);
    color: var(--green-170);
}

.multi-search-input .select__multi-value {
    margin: 0.1875rem 0.3125rem 0.1875rem 0;
    padding: 0.125rem 0.125rem 0.125rem 0.5625rem;
}

.multi-search-input .select__multi-value__remove {
    padding: 0.125rem;
    border-radius: 0.625rem;
    margin: 0 0.125rem;
    cursor: pointer;
}

.multi-search-input .select__multi-value__label {
    color: var(--blue-120);
    padding: 0;
    font-size: 1em;
}

.custom-tag-container {
    width: 100%;
    padding: 0.375rem;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.navbar-title {
    font-weight: 600;
    margin-left: 0.625rem;
}

.custom-tag-container.selected {
    padding: 0.125rem 0;
}

.invoice-search-input .select__input {
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
}

.searchbar-container .select__input {
    height: 26px;
}

.invoice-search-input .select__value-container--has-value .select__input-container {
    margin: 0;
    position: absolute;
    opacity: 0;
}

.custom-tag-container.selected:hover {
    background-color: var(--white-100);
}

.custom-tag-container.selected .invoice-option-select {
    margin-right: 0;
    padding: 0.125rem 0.5625rem;
}

.single-search-input.invoice-search-input .select__value-container,
.multi-search-input.invoice-search-input .select__value-container {
    padding: 0.1875rem 0.375rem;
}

.single-search-input .select__value-container.select__value-container--has-value div:last-child {
    margin: 0;
}

.searchbar-container .single-search-input .select__single-value {
    top: 1.5625rem;
}

.custom-tag-container:hover {
    background: var(--black-5);
}

.selected-tag {
    position: absolute;
    z-index: 10;
}

.hidden {
    display: none;
}

.single-search-input,
.multi-search-input {
    width: 100%;
    border-radius: 0.5rem;
}

.single-search-input .select__value-container,
.multi-search-input .select__value-container {
    padding-left: 2.5rem;
}

.single-search-input .select__control,
.multi-search-input .select__control {
    border-radius: 0.5rem;
    min-height: 2.5rem;
    cursor: pointer;
    border-color: var(--black-10);
}

.searchbar-container .input-container {
    width: 100%;
}

.searchbar-container .select__control,
.searchbar-container .select__value-container {
    height: 2.5rem;
}

.single-search-input .select__menu,
.multi-search-input .select__menu,
body .select__menu {
    z-index: 9999;
    border-radius: 0.75rem;
    overflow: hidden;
}

.single-search-input .select__menu-list::-webkit-scrollbar,
.multi-search-input .select__menu-list::-webkit-scrollbar {
    display: none;
}

.single-search-input .select__menu-list,
.multi-search-input .select__menu-list {
    padding: 0;
    -ms-overflow-style: none;
}

.scrollable-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
}

.list-container {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

.list-container table {
    width: 100%;
    font-size: .9em;
    white-space: nowrap;
}

.list-container tr {
    height: 2.9688rem;
}

.scroll-container {
    overflow-x: scroll;
}

.sticky-col {
    position: sticky;
    z-index: 9999;
}

.sticky-col-first {
    left: 0;
}

.sticky-col-second {
    left: 3.125rem;
}

.small-list-container .list-container tr {
    height: 1.25rem;
}

.list-container thead {
    background: var(--blue-5);
    border-bottom: 0.0625rem solid var(--black-10);
    color: var(--black-70);
}

.list-container tbody tr {
    border-bottom: 0.0625rem solid var(--black-5);
    transition: all 0.3s ease-in-out;
}

.list-container tbody tr:hover {
    background-color: var(--black-10);
}

.list-container td:not(:first-child) {
    padding-inline: 1.5rem;
}

.table-fixed td:first-child {
    text-wrap: balance;
}

.table-fixed td .custom-tag {
    margin-block: 0.625rem;
    word-wrap: break-word;
    text-wrap: wrap;
}

.loader-container {
    padding: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container.absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.9375rem;
    font-size: .9em;
    color: var(--black-70);
    font-weight: 500;
    border-top: 0.0625rem solid var(--black-10);
    position: sticky;
    left: 0;
    bottom: 0;
}

.checkbox-container {
    display: block;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1.875rem;
    height: 2.8125rem;
}

.checkbox-container.pastille-checkbox-div {
    padding-left: 2.8125rem;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0.8125rem;
    left: 0.8125rem;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 0.4375rem;
    background: var(--black-5);
    border: 0.0625rem solid var(--black-15);
    transition: all .2s;
}

.checkbox-container:hover input~.checkmark {
    background-color: #efefef;
}

.checkbox-container input:checked~.checkmark {
    background-color: var(--blue-100);
    border: 0.0625rem solid var(--blue-100);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 0.25rem;
    top: 0.25rem;
    width: 0.625rem;
    height: 0.625rem;
    background: url('../icons/check--white.svg') no-repeat;
    background-size: cover;
    transition: all .2s;
}

.page-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}

.pagination-button {
    border: 0.0625rem solid var(--black-10);
    background-color: var(--white-100);
    border-radius: 0.625rem;
    width: 2rem;
    height: 2rem;
    font-weight: 600;
    color: var(--black-70);
    padding: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.pagination-button.select {
    width: fit-content;
}

.pagination-button:hover {
    background: var(--blue-100);
    border: 0.0625rem solid var(--blue-100);
    color: var(--white-100);
}

.pagination-button:disabled {
    border: 0.0625rem solid var(--black-15);
    color: var(--black-15);
}

.pagination-button:disabled:hover {
    background-color: var(--white-100);
    border: 0.0625rem solid var(--black-15);
    color: var(--black-15);
}

.padding-right {
    padding-right: 0.625rem;
}

.list-container th {
    position: relative;
}

.sorted,
.sorted-desc {
    position: relative;
}

.sorted:before {
    content: '';
    position: absolute;
    left: 0.5rem;
    top: 0.4375rem;
    width: 0.5rem;
    height: 0.5rem;
    background: url('../icons/caret-up--gray.svg') no-repeat;
    background-size: contain;
}

.sorted-desc:before {
    content: '';
    position: absolute;
    left: 0.5rem;
    top: 0.4375rem;
    width: 0.5rem;
    height: 0.5rem;
    background: url('../icons/caret-down--gray.svg') no-repeat;
    background-size: contain;
}

.small-list-container .list-container .column-icon:before {
    display: none;
}

.small-list-container .list-container .column-icon {
    padding-left: 0;
}

.small-list-container .list-container.non-selectable thead th:first-child {
    padding-left: 1.875rem;
}

.small-list-container {
    border: 0.0625rem solid var(--black-5);
    border-radius: 0.625rem;
    overflow: hidden;
}

.list-container .column-icon {
    padding-inline-start: 3.125rem;
    padding-inline-end: 0;
    position: relative;
}

.list-container .column-icon:before {
    content: '';
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translate(0, -50%);
    width: 0.9375rem;
    height: 0.9375rem;
    background: url('../icons/supervisor--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.email:before {
    background: url('../icons/project--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.pen:before {
    background: url('../icons/pen--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.id:before {
    background: url('../icons/id--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.euro:before {
    background: url('../icons/euro--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.customer:before {
    background: url('../icons/customer--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.calendar:before {
    background: url('../icons/calendar--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.order:before {
    background: url('../icons/order--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.role:before {
    background: url('../icons/tag-simple--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.percentage:before {
    background: url('../icons/percentage--grey.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.hotel:before {
    background: url('../icons/location--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.engine:before {
    background: url('../icons/globe--grey.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.type:before {
    background: url('../icons/squares-four--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.provider:before {
    background: url('../icons/supplier--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.project:before {
    background: url('../icons/folder-open--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.support:before {
    background: url('../icons/wrench--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.link:before {
    background: url('../icons/link-alt--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.cms:before {
    background: url('../icons/cms--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.phone:before {
    background: url('../icons/phone--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.email:before {
    background: url('../icons/email--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.adresse:before {
    background: url('../icons/map-pin--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.ville:before {
    background: url('../icons/city--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.poste:before {
    background: url('../icons/building--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.conso:before {
    background: url('../icons/consommation--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.abonnement:before {
    background: url('../icons/type-abonnement--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.frais-setup:before {
    background: url('../icons/euro--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.article:before {
    background: url('../icons/api--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.objet:before {
    background: url('../icons/messagerie--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.document:before {
    background: url('../icons/document--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tag.deactivated {
    background: var(--black-5);
    color: var(--black-60);
}

.custom-tag.date {
    color: var(--black-100);
    border-radius: 6px;
    border: 1px solid var(--black-10);
    background-color: var(--white-100);
}

.custom-tag.active,
.custom-tag.closed,
.custom-tag.credit,
.custom-tag.true {
    background: var(--green-30);
    color: var(--green-170);
}

.custom-tag.progress,
.custom-tag.dedge-smart,
.custom-tag.premium {
    background: var(--yellow-30);
    color: var(--yellow-170);
}

.custom-tag.production,
.custom-tag.not-started,
.custom-tag.uniforme-prestige-school,
.custom-tag.reservit {
    background: var(--orange-30);
    color: var(--orange-120);
}

.custom-tag.inactive,
.custom-tag.difficulty,
.custom-tag.debit,
.custom-tag.false {
    background: var(--red-30);
    color: var(--red-170);
}

.custom-tag.archived,
.custom-tag.draft {
    background: var(--black-5);
    color: var(--black-70);
}

.custom-tag.fabrics,
.custom-tag.dedge-attraction {
    background: var(--purple-30);
    color: var(--purple-120);
}

.custom-tag.gray,
.custom-tag.default {
    background: var(--black-5);
    color: var(--black-100);
}

.custom-tag.brown {
    color: #976D57;
    background-color: #F3EEEE;
}

.custom-tag.orange {
    color: #CC772F;
    background-color: #F8ECDF;
}

.custom-tag.yellow {
    color: #C29243;
    background-color: #FAF3DD;
}

.custom-tag.green {
    color: #548064;
    background-color: #EEF3ED;
}

.custom-tag.blue {
    background-color: var(--blue-50);
}

.custom-tag.purple {
    color: #A48BBE;
    background-color: #F6F3F8;
}

.custom-tag.pink {
    color: var(--pink-120);
    background-color: var(--pink-30);
}

.custom-tag.red {
    color: #C4554D;
    background-color: #FAECEC;
}

.list-container a {
    text-decoration: none;
    color: var(--black-100);
    font-weight: 500;
}

.view-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.25rem;
}

.view-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2.8125rem;
}

.view-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.view-actions button svg {
    color: var(--blue-100);
}

.view-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}

.view-title h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.custom-modal-header h1 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}

.view-header .MuiButtonBase-root:not(.MuiSwitch-switchBase) {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.3125rem;
    background-color: var(--white-100);
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
    text-transform: capitalize;
    color: var(--black-100);
    font-weight: 600;
}

.view-content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.filter-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    width: calc(100% - 1rem);
    background-color: var(--white-100);
}

.filter-title-row.top {
    top: 0;
    z-index: 999;
}

.filter-title-row.bottom {
    bottom: 0;
    z-index: 999;
}

.filter-section {
    margin-top: 1rem;
}

.filter-titre {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.filter-titre .MuiTypography-root {
    color: #4B4B4B;
    font-weight: 600;
}

.filter-titre .MuiButtonBase-root {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.3125rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
    background-color: var(--white-100);
    color: var(--black-100);
    font-weight: 600;
    text-transform: capitalize;
}

.view-header .MuiButtonBase-root:not(.MuiSwitch-switchBase) {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.3125rem;
    background-color: var(--white-100);
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
    text-transform: capitalize;
    color: var(--black-100);
    font-weight: 600;
}

.portal-title h1,
h1.order-portal-welcome,
.test-content h1 {
    font-size: 1.5em;
    font-weight: 800;
    position: relative;
    margin-bottom: 0;
}

.view-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.margin-inline {
    margin-inline: 0.625rem;
}

.margin-left {
    margin-left: 0.625rem;
}

.margin-right {
    margin-right: 0.625rem;
}

.margin-top {
    margin-top: 0.625rem;
}

.margin-bottom-15 {
    margin-bottom: 0.9375rem;
}

.view-title .view-id,
.portal-title span,
.view-header .view-id {
    color: var(--black-50);
    font-weight: 600;
    display: block;
    padding-top: 0.3125rem;
}

.classic-input,
.search-input,
.contact-container {
    display: flex;
    height: 2.375rem;
    padding: 0.75rem;
    width: 100%;
    align-items: center;
    gap: 0.3125rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--black-10);
    background-color: var(--white-100);
}

.classic-input.large {
    height: 2.5rem;
}

.classic-input.fit {
    width: fit-content;
}

.search-input {
    padding-left: 2.5rem;
}

.input-required,
.input-required:focus-visible {
    border: 0.125rem solid var(--red-100);
    outline: transparent;
}

.relative {
    position: relative;
}

.input-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-date-container .react-datepicker {
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--black-15);
}

.input-date-container .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: var(--black-15);
}

.input-date-container .react-datepicker__header {
    border-top-left-radius: 0.75rem;
    border-bottom: 0.0625rem solid var(--black-15);
}

.input-date-container .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.75rem;
}

.input-date-container .react-datepicker__year-read-view--down-arrow,
.input-date-container .react-datepicker__month-read-view--down-arrow,
.input-date-container .react-datepicker__month-year-read-view--down-arrow,
.input-date-container .react-datepicker__navigation-icon::before {
    top: 0.75rem;
}

input.name-input-required::placeholder {
    color: var(--red-30);
}

.input-date-container .date-view,
.input-classic-container {
    height: 2.5rem;
    border: 0.0625rem solid var(--black-15);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-radius: 0.75rem;
    font-weight: 500;
}

.input-classic-container {
    width: 100%;
    height: 3.125rem;
    border-radius: 1rem;
    padding: 0 1.25rem;
    justify-content: left;
}

th.checkbox-fixed-size {
    width: 3.75rem;
}

.main-button.small-button {
    height: 2.1875rem;
    padding: 0 0.75rem 0 2.1875rem;
}

.classic-input.number-input {
    width: 6.25rem;
}

.support-tooltip-container {
    background: white;
    padding: 0.625rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--black-5);
}

.support-tooltip-data {
    border-top: 0.0625rem solid var(--black-5);
    padding-top: 0.625rem;
    margin-top: 0.625rem;
    display: flex;
    flex-direction: column;
}

.support-tooltip-data .support-exceeded {
    color: #dc3545;
}

.support-tooltip-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recharts-label tspan {
    font-weight: 600;
}

.support-tooltip-title h1 {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 0;
}

.main-button.small-button.without-icon {
    padding: 0 0.75rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

label.main-button.small-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-button.small-button.blue-button:before {
    left: 0.625rem;
    top: 0.5625rem;
}

.contact-container .delete-icon-button {
    top: 0.625rem;
}

.update-icon-button {
    position: absolute;
    top: 0.6875rem;
    right: 2.5rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.9375rem;
    border: 0;
    background-color: var(--white-100);
    transition: all .3s;
}

.update-icon-button:before {
    content: '';
    position: absolute;
    left: 0.4375rem;
    top: 0.375rem;
    width: 1.0625rem;
    height: 1.0625rem;
    background: url('../icons/pen--blue.svg') no-repeat;
    background-size: contain;
}

.custom-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .2);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal.hidden {
    display: none;
}

.custom-modal-container {
    width: 37.5rem;
    min-height: 15vh;
    background-color: var(--white-100);
    border-radius: 0.75rem;
    padding: 1.25rem;
}

.custom-modal-header {
    border-bottom: 0.0625rem solid var(--black-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem;
}

.custom-modal-header .modal-close {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.9375rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    transform: rotate(45deg);
    border: 0;
    background: transparent;
    transition: all .3s;
}

.custom-modal-header .modal-close:hover {
    background: var(--black-5);
}

.submit-right-container {
    padding-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.contact-container {
    width: 100%;
    height: 3.125rem;
    display: flex;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.9375rem;
    position: relative;
    padding: 0 4.6875rem 0 1.25rem;
    font-weight: 500;
}

.update-icon-button:hover {
    background: var(--blue-5);
}

.contact-container.full-width {
    padding: 0 1.25rem;
}

.contact-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-info span {
    width: 100%;
}

.input-address-container {
    font-weight: 500;
    width: 100%;
    border: 0.0625rem solid var(--black-15);
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    border-radius: 1rem;
    position: relative;
}

.input-address-container:before {
    top: 0.9375rem;
}

.no-value {
    font-weight: 500;
    width: 100%;
    background: var(--red-30);
    border: 0.0625rem solid var(--red-100);
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding: 0 0.9375rem;
    border-radius: 1rem;
    position: relative;
}

.provider-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.provider-block {
    width: 13.125rem;
    height: 7.5rem;
    margin-right: 1.125rem;
    border: 0.0625rem solid var(--black-15);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all .3s;
}

.provider-block.fabrics:hover,
.provider-block.fabrics.checked {
    background: var(--purple-30);
    border: 0.0625rem solid var(--purple-60);
}

.provider-block.fabrics:hover .provider-check,
.provider-block.fabrics.checked .provider-check {
    border: 0.0625rem solid var(--purple-60);
}

.provider-block .provider-check:before {
    content: '';
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 1rem;
    height: 1rem;
    background: transparent;
    border-radius: 0.625rem;
    transition: all .3s;
}

.provider-block.fabrics.checked .provider-check:before {
    background: var(--purple-100);
}

.provider-block.production.checked .provider-check:before {
    background: var(--orange-100);
}

.provider-block.logistics.checked .provider-check:before {
    background: var(--blue-100);
}

.provider-block.production:hover,
.provider-block.production.checked {
    background: var(--orange-30);
    border: 0.0625rem solid var(--orange-60);
}

.provider-block.production:hover .provider-check,
.provider-block.production.checked .provider-check {
    border: 0.0625rem solid var(--orange-60);
}

.provider-block.logistics:hover,
.provider-block.logistics.checked {
    background: var(--blue-30);
    border: 0.0625rem solid var(--blue-60);
}

.provider-block.logistics:hover .provider-check,
.provider-block.logistics.checked .provider-check {
    border: 0.0625rem solid var(--blue-60);
}

.provider-check {
    position: absolute;
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 0.6875rem;
    border: 0.0625rem solid var(--black-15);
    top: 0.75rem;
    right: 0.75rem;
    background: var(--black-5);
    transition: all .3s;
}

.provider-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
}

.production .provider-content img {
    height: 1.875rem;
}

.provider-content img {
    height: 1.625rem;
    margin-bottom: 0.625rem;
}

#file-add {
    width: 0.0625rem;
    height: 0.0625rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-block {
    height: 11.875rem;
    width: 12.5rem;
    margin-right: 1.25rem;
    border-radius: 0.75rem;
    box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.08);
    display: inline-block;
    margin-bottom: 1.25rem;
}

.file-preview {
    height: 6.875rem;
    width: 100%;
    border-radius: 0.75rem 0.75rem 0 0;
    overflow: hidden;
}

.file-info {
    height: 5rem;
    border-radius: 0 0 0.75rem 0.75rem;
    position: relative;
}

.file-info span {
    width: calc(100% - 0.625rem);
    display: block;
    padding: 0.3125rem 0.625rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
}

.file-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.file-buttons a {
    height: 1.875rem;
    display: flex;
}

.file-button {
    width: 2.5rem;
    height: 1.875rem;
    border-radius: 0.375rem;
    border: 0.0625rem solid var(--blue-30);
    background-color: var(--white-100);
    margin-left: 0.3125rem;
    position: relative;
    transition: all .3s;
}

.file-button:hover {
    border: 0.0625rem solid var(--blue-100);
    background: var(--blue-100);
}

.file-button.view:hover:before {
    background: url('../icons/see-more--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:hover {
    border: 0.0625rem solid var(--red-100);
    background: var(--red-100);
}

.file-button.delete {
    border: 0.0625rem solid var(--red-30);
}

.file-button.view:before {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: 0.5625rem;
    width: 1.25rem;
    height: 1.25rem;
    background: url('../icons/see-more--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.file-button.download:before {
    content: '';
    position: absolute;
    top: 0.375rem;
    left: 0.75rem;
    width: 1rem;
    height: 1rem;
    background: url('../icons/download--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.file-button.download:hover:before {
    background: url('../icons/download--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:before {
    content: '';
    position: absolute;
    top: 0.375rem;
    left: 0.6875rem;
    width: 1rem;
    height: 1rem;
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
}

.files-container {
    margin-bottom: 1.25rem;
    width: 100%;
}

.custom-page-file canvas {
    width: 12.5rem !important;
    height: auto !important;
    min-height: 6.875rem !important;
}

.file-preview img {
    width: 12.5rem;
    height: auto;
    min-height: 6.875rem;
}

.scrollable-page {
    overflow-y: scroll;
}

.scrollable-actions {
    overflow-y: scroll;
    height: calc(100% - 7.1875rem);
}

.frequency-container {
    font-weight: 500;
}

.frequency-container input,
.frequency-container select {
    margin-left: 0.625rem;
    height: 2.1875rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-15);
    padding: 0 0.625rem;
}

.flex-view {
    height: 100%;
    display: flex;
    justify-content: center;
}

.flex-view .left-container,
.portal-content .left-container {
    width: 60%;
}

.flex-view .right-container,
.portal-content .right-container {
    width: 40%;
    border-left: 0.0625rem solid var(--black-5);
    position: relative;
}

.order-portal .flex-view .right-container {
    height: calc(100% - 6.25rem);
}

.action-date {
    font-size: .8em;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--black-50);
    text-align: center;
    padding: 0.375rem 0;
}

.action-block {
    padding: 0.375rem 0;
}

.custom-action:first-child {
    padding-top: 0.375rem;
}

.custom-action:last-child {
    padding-bottom: 0.375rem;
}

.action-block.message,
.order-portal .action-block.provider-message {
    display: flex;
    padding-right: 1.5625rem;
    justify-content: flex-end;
}

.action-block.provider-message,
.order-portal .action-block.message {
    display: flex;
    padding-left: 1.5625rem;
    justify-content: flex-start;
}

.action-block.provider-message span,
.order-portal .action-block.message span {
    white-space: pre-line;
    display: block;
    width: 75%;
    background: var(--black-5);
    padding: 0.9375rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: .92em;
    color: var(--black-100);
}

.action-block.message span,
.order-portal .action-block.provider-message span {
    white-space: pre-line;
    display: block;
    width: 75%;
    background: var(--blue-100);
    color: white;
    padding: 0.9375rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: .92em;
}

.action-block.date-event,
.action-block.receipt-event,
.action-block.start-event,
.action-block.reminder-event,
.action-block.creation-event,
.action-block.date-reminder,
.action-block.date-confirm-reminder,
.action-block.receipt-reminder,
.action-block.confirm-event {
    display: flex;
    justify-content: center;
    font-size: .9em;
    font-weight: 600;
    color: var(--black-60);
    padding: 0.5rem 1rem;
    text-align: center;
}

.action-block span {
    position: relative;
}

.empty-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25rem;
}

.empty-list-container span {
    font-weight: 500;
    margin-top: 1.875rem;
}

.action-block.date-event span,
.action-block.receipt-event span,
.action-block.start-event span,
.action-block.reminder-event span,
.action-block.creation-event span,
.action-block.date-reminder span,
.action-block.date-confirm-reminder span,
.action-block.receipt-reminder span,
.action-block.confirm-event span {
    padding-left: 1.375rem;
}

.action-block.date-event span:before,
.action-block.confirm-event span:before {
    content: '';
    position: absolute;
    top: 0.1875rem;
    left: 0;
    width: 0.9375rem;
    height: 0.9375rem;
    background: url('../icons/calendar--gray.svg') no-repeat;
    background-size: contain;
}

.action-block.date-reminder span:before,
.action-block.date-confirm-reminder span:before,
.action-block.receipt-reminder span:before {
    content: '';
    position: absolute;
    top: 0.1875rem;
    left: 0;
    width: 0.9375rem;
    height: 0.9375rem;
    background: url('../icons/reminder--gray.svg') no-repeat;
    background-size: contain;
}

.action-block.creation-event span:before {
    content: '';
    position: absolute;
    top: 0.1875rem;
    left: 0;
    width: 0.875rem;
    height: 0.875rem;
    background: url('../icons/plus--gray.svg') no-repeat;
    background-size: contain;
}

.action-block.start-event span:before {
    content: '';
    position: absolute;
    top: 0.125rem;
    left: -0.125rem;
    width: 1.125rem;
    height: 1.125rem;
    background: url('../icons/launch--gray.svg') no-repeat;
    background-size: contain;
}

.action-block.receipt-event span:before {
    content: '';
    position: absolute;
    top: 0.1875rem;
    left: 0;
    width: 0.9375rem;
    height: 0.9375rem;
    background: url('../icons/order--gray.svg') no-repeat;
    background-size: contain;
}

.action-block.reminder-event span:before {
    content: '';
    position: absolute;
    top: 0.1875rem;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: url('../icons/reminder--gray.svg') no-repeat;
    background-size: contain;
}

.action-sender-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    height: 7.1875rem;
    width: 100%;
    border-top: 0.0625rem solid var(--black-5);
}

.action-sender-input {
    width: 85%;
    padding: 1.25rem 0 1.25rem 1.25rem;
    height: 100%;
}

.action-sender-input textarea {
    background: var(--black-5);
    border: 0;
    border-radius: 1rem;
    width: 100%;
    padding: 0.625rem 0.9375rem;
    height: 100%;
    resize: none;
}

.action-sender-button {
    width: 15%;
    padding: 1.875rem 0.9375rem 1.875rem 0.9375rem;
    height: 100%;
}

.sender-button {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 1rem;
    position: relative;
    background-color: var(--white-100);
    transition: all .3s;
}

.sender-button:before {
    content: '';
    position: absolute;
    top: 1.125rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 1.375rem;
    height: 1.375rem;
    background: url('../icons/send--blue.svg') no-repeat;
    background-size: contain;
}

.sender-button:hover {
    background: var(--blue-5);
}

.order-portal {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.portal-header {
    height: 6.25rem;
    border-bottom: 0.0625rem solid var(--black-5);
    padding: 0 1.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.portal-title .logo-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 1.875rem;
    margin-right: 1.875rem;
    justify-content: center;
    border-right: 0.0625rem solid var(--black-5);
}

.portal-phone {
    border: 0.0625rem solid var(--black-15);
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.9375rem 0 2.8125rem;
    border-radius: 0.75rem;
    color: var(--black-100);
    transition: all .3s;
    position: relative;
    text-decoration: none;
}

.portal-phone:before {
    content: '';
    position: absolute;
    left: 0.9375rem;
    top: 0.5625rem;
    width: 1.25rem;
    height: 1.25rem;
    background: url('../icons/phone--black.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.portal-phone:hover {
    text-decoration: none;
    color: var(--blue-100);
    background: var(--blue-5);
    border: 0.0625rem solid var(--blue-40);
}

.portal-phone:hover:before {
    background: url('../icons/phone--blue.svg') no-repeat;
    background-size: contain;
}

/* .order-portal-welcome {
    padding-left: 2.5rem;
}

.order-portal-welcome:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.75rem;
    height: 1.75rem;
    background: url('../icons/hello.svg') no-repeat;
    background-size: contain;
} */

.waiting-order {
    height: 3.75rem;
    border: 0.0625rem solid var(--black-15);
    border-radius: 1.125rem;
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem 0 1.25rem;
}

.waiting-orders-container p {
    margin-bottom: 0.625rem;
}

.waiting-order .no-border,
.waiting-order.disabled .no-border {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}

.provider-name {
    padding-left: 1.875rem;
    position: relative;
    font-weight: 600;
}

.provider-name:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: url('../icons/order--blue.svg') no-repeat;
    background-size: contain;
}

.waiting-orders-container {
    font-weight: 500;
    margin-bottom: 1.875rem;
}

.order-receipt-confirm,
.datepicker-validate,
.datepicker-delete {
    height: 2.5rem;
    border: 0.0625rem solid var(--green-170-40);
    background: var(--green-30);
    border-radius: 0.75rem;
    padding: 0 0.9375rem;
    color: var(--green-170);
    font-weight: 600;
    transition: all .3s;
}

.datepicker-delete {
    border: 0.0625rem solid var(--red-170-40);
    background: var(--red-30);
    color: var(--red-170);
    margin-top: 0.875rem;
}

.datepicker-delete:hover {
    background: var(--red-100);
    color: var(--white-100);
    border: 0.0625rem solid var(--red-100);
}

.order-receipt-confirm:hover,
.datepicker-validate:hover {
    background: var(--green-100);
    color: var(--white-100);
    border: 0.0625rem solid var(--green-100);
}

.date-edit-buttons {
    display: flex;
    width: 12.1875rem;
}

.datepicker-validate {
    margin-top: 0.875rem;
    width: 100%;
    margin-right: 0.3125rem;
}

.erased {
    color: var(--black-50);
    font-style: italic;
}

.datepicker-delete,
.waiting-order.disabled {
    width: 100%;
}

.waiting-orders-block .waiting-order.disabled {
    background: var(--black-5);
    padding: 0 1.25rem;
}

.waiting-orders-block .waiting-order.disabled span {
    color: var(--black-60);
}

.waiting-order.disabled .provider-name:before {
    background: url('../icons/order--gray.svg') no-repeat;
    background-size: contain;
}

.waiting-order-warning {
    background: var(--blue-5);
    color: var(--blue-100);
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.9375rem;
    border-radius: 0.75rem;
    font-size: .9em;
}

.view-container .waiting-order-warning {
    margin-bottom: 0;
}

.view-container .waiting-order-warning.danger {
    background: var(--red-30);
    color: var(--red-170);
}

.delivery-date-container {
    padding: 0.875rem;
    border: 0.0625rem solid var(--black-15);
    border-radius: 1.125rem;
    font-weight: 500;
}

.delivery-date-title {
    padding-left: 2.1875rem;
    position: relative;
}

.delivery-date-title:before {
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 1.375rem;
    height: 1.375rem;
    background: url('../icons/shipping--blue.svg') no-repeat;
    background-size: contain;
}

.warning-delivery {
    color: var(--red-100);
}

.delivery-container {
    min-height: 6.875rem;
    display: flex;
}

.datepicker-block {
    width: 12.5rem;
    display: flex;
    position: relative;
}

.datepicker-block .react-datepicker__input-container {
    height: 100%;
}

.datepicker-block .estimation-button,
.estimated-date {
    height: 100%;
    width: calc(100% - 0.3125rem);
    background: var(--blue-100);
    border: 0;
    border-radius: 0.75rem 0.125rem 0.125rem 0.75rem;
    color: white;
    transition: all .3s;
    cursor: pointer;
    position: relative;
    caret-color: transparent;
    text-align: center;
    padding-top: 2.875rem;
    font-weight: 600;
    font-size: 1.1em;
}

.datepicker-block .estimation-button:disabled {
    background: var(--black-50);
    cursor: not-allowed;
}

.datepicker-block .estimation-button:disabled:hover {
    background: var(--black-50);
}

.estimated-date-edit {
    position: absolute;
    top: 0.3125rem;
    right: 0.3125rem;
    padding: 0.3125rem;
    width: 1.5625rem;
    height: 1.5625rem;
    border-radius: 0.25rem;
    transition: all .2s;
}

.estimated-date-edit:hover {
    cursor: pointer;
    background: var(--blue-60);
}

.estimated-date h1 {
    font-weight: 700;
    font-size: 2em;
    margin-bottom: 0;
}

.estimated-date h4 {
    font-size: 1.1em;
    font-weight: 600;
}

.datepicker-block .estimated-date {
    padding-top: 0.375rem;
}

.datepicker-block .estimation-button:hover {
    background: var(--blue-120);
}

.datepicker-block .estimation-button::placeholder,
.datepicker-block .estimation-button::target-text {
    font-weight: 600;
    font-size: 1.1em;
    color: white;
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translate(-50%, 0);
}

.datepicker-image {
    position: absolute;
    z-index: 9;
    top: 20%;
    left: calc(50% - 0.3125rem);
    transform: translate(-50%, 0);
    cursor: pointer;
}

.datepicker-block .estimation-button img {
    margin-bottom: 0.625rem;
}

.address-block {
    width: calc(100% - 12.5rem);
    background: var(--blue-5);
    border-radius: 0.125rem 0.75rem 0.75rem 0.125rem;
    display: flex;
    flex-direction: column;
    padding: 0.625rem 0.625rem 0.625rem 1.5625rem;
}

.address-block span {
    margin-bottom: 0.125rem;
}

.address-block .delivery {
    font-weight: 600;
    color: var(--blue-120);
}

.address-block .provider-title {
    font-weight: 700;
    font-size: 1.3em;
}

.address-block .provider-address {
    font-weight: 600;
}

.order-status-bar-container {
    display: flex;
    align-items: center;
}

.order-status-item {
    background: var(--black-5);
    border-radius: 0.625rem;
    padding: 0.125rem 0.5rem 0.125rem 1.625rem;
    margin-right: 0.375rem;
    position: relative;
}

.order-status-item.delivered {
    background: var(--green-30);
}

.order-status-item.delivered .order-status-tip {
    border: 0.0625rem solid var(--green-100);
    background: var(--green-100);
}

.order-status-item.delivered .order-status-tip:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.6875rem;
    height: 0.5rem;
    transform: translate(-50%, -50%);
    background: url('../icons/save--white.svg') no-repeat;
    background-size: contain;
}

.order-status-item.in-progress {
    background: var(--yellow-30);
}

.order-status-item.in-progress .order-status-tip {
    border: 0.0625rem solid var(--yellow-100);
}

.order-status-item.difficulty {
    background: var(--red-30);
}

.order-status-item.difficulty .order-status-tip {
    border: 0.0625rem solid var(--red-100);
    background: var(--red-100);
}

.order-status-item.difficulty .order-status-tip:before {
    content: '!';
    color: white;
    font-weight: 700;
    font-size: .9em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.order-status-item .order-status-tip {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0.25rem;
    width: 1.125rem;
    height: 1.125rem;
    border: 0.0625rem solid var(--black-15);
    border-radius: 0.4375rem;
}

.order-404,
.subscribed {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscribed img {
    margin: 6.25rem 0 1.875rem 0;
}

.subscribed {
    max-width: 40.625rem;
    text-align: center;
}

.order-404 h1 {
    margin-top: 1.25rem;
}

.custom-filter {
    position: absolute;
    right: 0;
    top: 0;
    background: var(--blue-20);
    height: 100%;
    width: 21.875rem;
    z-index: 99;
    transition: all ease-in-out 0.3s;
}

.custom-filter.closed {
    right: -21.875rem;
}

.page-content {
    position: relative;
    height: calc(100vh - 5.3125rem);
}

.custom-filter-header .close-button {
    background: var(--blue-100);
    border: 0;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 0.75rem;
    position: relative;
    transition: all .3s;
}

.custom-filter-header .close-button:hover {
    background: var(--blue-120);
}

.custom-filter-header .close-button:before {
    content: '+';
    color: white;
    font-size: 3em;
    transform: rotate(45deg);
    position: absolute;
    top: -1.125rem;
    left: 0.3125rem;
    font-weight: 100;
}

.custom-filter-header {
    display: flex;
    align-items: center;
    padding: 1.25rem;
}

.custom-filter-header h1 {
    font-size: 1.4em;
    font-weight: 800;
    margin-left: 1rem;
    margin-bottom: 0;
}

.custom-filter .bottom-buttons {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1.25rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.0625rem solid var(--blue-60);
}

.bottom-buttons .transparent-button {
    margin-left: 1.25rem;
}

.custom-filter .bottom-buttons * {
    width: 100%;
}

.transparent-button {
    background: transparent;
    border: 0;
    font-weight: 500;
    color: var(--blue-100);
    height: 2.5rem;
    transition: all .3s;
    border-radius: 0.75rem;
}

.transparent-button:hover {
    background: var(--blue-30);
}

.filter-input-container {
    padding: 0 1.25rem;
}

.custom-filter .filter-input .filter-title {
    font-weight: 600;
    display: block;
    font-size: .9em;
    margin-bottom: 0.625rem;
}

.custom-filter .input-container {
    width: 100%;
}

.filter-input {
    margin-bottom: 1.25rem;
}

.filter-input .input-date-container {
    display: flex;
    position: relative;
}

.filter-input .input-date-container:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.0625rem;
    background: var(--black-30);
}

.filter-input .input-date-container .classic-input {
    width: 9rem;
}

.stop-date .react-datepicker-wrapper .react-datepicker__input-container {
    display: flex;
    justify-content: flex-end;
}

.order-delivery-add-container {
    width: calc(100% - 12.5rem);
    display: flex;
    align-items: center;
}

.order-delivery-add-transition {
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
}

.customer-type .provider-content img {
    height: 2.5rem;
}

.margin-top-30 {
    margin-top: 1.875rem;
}

.final-delivery-confirm {
    margin-top: 1.25rem;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(0.625rem + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.form-container {
    padding: 0.625rem;
    text-align: left;
}

.sigCanvas {
    border: 0.0625rem solid #c6c6c6;
    border-radius: 0.75rem;
    width: 100%;
    min-height: 9.375rem;
}

.erase-signature {
    position: absolute;
    top: 0.5rem;
    right: 0.375rem;
}

.erase-signature button {
    border-radius: 0.4375rem;
}

.signature-container {
    position: relative;
}

.loader-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-signature {
    position: absolute;
    left: 0.375rem;
    top: 0.4375rem;
}

.transactions-container {
    border: 0.0625rem solid var(--black-5);
    border-radius: 0.75rem;
    overflow: hidden;
}

.transactions-container .transaction-block {
    border-bottom: 0.0625rem solid var(--black-5);
    padding: 1.25rem 1.25rem 0.75rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transactions-container .transaction-title {
    font-weight: 500;
}

.transactions-container .transaction-title div:first-child {
    display: flex;
    flex-direction: column;
}

.transactions-container .transaction-titles {
    display: flex;
    flex-direction: column;
}

.transactions-container .transaction-titles span:first-child {
    font-weight: 600;
}

.transactions-container .discreet {
    color: var(--black-30);
    font-size: .9em;
}

.transactions-container .MuiGrid-item {
    display: flex;
    align-items: center;
}

.transactions-header {
    padding: 0.625rem 1.25rem;
    background: var(--blue-5);
}

.transactions-header span {
    font-weight: 600;
    font-size: 0.9em;
    color: var(--black-70);
}

.form-container h3 {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 1.875rem;
}

.transaction-block.gocardless-block {
    background: var(--black-5);
    flex-direction: column;
    align-items: unset;
}

.transaction-titles.gocardless {
    padding-left: 1.25rem;
    position: relative;
}

.transaction-titles.gocardless:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 0.25rem;
    height: calc(100%);
    background: var(--blue-100);
    border-radius: 0.25rem 0.25rem 0 0;
}

.transaction-titles.gocardless:after {
    content: '';
    position: absolute;
    top: calc(50% - 0.375rem);
    left: -0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    background: var(--blue-100);
    border-radius: 0.375rem;
}

.invoice-finder-select {
    border: 0.0625rem solid var(--black-15);
    border-radius: 0.5rem;
    height: 1.875rem;
    padding: 0 0.375rem;
    width: 15rem;
    cursor: pointer;
}

#transactions-importer .invoice-finder-select {
    width: 100%;
    height: 2.5rem;
}

#transactions-importer {
    max-height: 31.25rem;
    overflow-y: scroll;
}

.gocardless-invoice-block {
    margin-bottom: 1rem;
}

.transaction-title.gocardless {
    padding-left: 1.25rem;
    position: relative;
}

.gocardless-invoice-block .MuiGrid-item:first-child {
    position: relative;
}

.gocardless-invoice-block:last-child .MuiGrid-item:first-child:before {
    height: 100%;
}

.gocardless-invoice-block .MuiGrid-item:first-child:before {
    content: '';
    position: absolute;
    top: -1rem;
    left: 1rem;
    width: 0.25rem;
    height: calc(100% + 1.25rem);
    background: var(--blue-100);
    border-radius: 0.25rem;
}

.transaction-title.gocardless:after {
    content: '';
    position: absolute;
    top: calc(50% - 0.3125rem);
    left: -0.1875rem;
    width: 0.625rem;
    height: 0.625rem;
    background: var(--blue-100);
    border-radius: 0.375rem;
    z-index: 9;
}

.gocardless-invoice-block:last-child {
    margin-bottom: 0;
}

.main-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--white-100);
    transform: translate(-50%, -50%);
    padding: 1.25rem;
    border-radius: 0.75rem;
    min-width: 37.5rem;
}

.main-modal .invoices-recap {
    max-height: 31.25rem;
    overflow-y: scroll;
    margin-bottom: 1.25rem;
}

.main-modal h5 {
    font-weight: 700;
    margin: 0.3125rem 0 1.875rem 0;
}

.invoice-recap-block {
    display: flex;
    position: relative;
}

.invoice-recap-details {
    display: flex;
    flex-direction: column;
    width: 31.25rem;
    border: 0.0625rem solid var(--black-15);
    border-radius: 0.75rem;
    margin-bottom: 1.25rem;
    padding: 0.625rem;
    position: relative;
    overflow-x: scroll;
}

.invoice-recap-details-price {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.invoice-recap-details-price span:first-child {
    max-width: 20.625rem;
}

.invoice-recap-details span:first-child {
    font-weight: 500;
}

.invoice-recap-details span {
    margin-bottom: 0.3125rem;
}

.invoice-recap-block .invoice-recap-details:first-child {
    margin-right: 2.5rem;
}

.invoice-recap-block:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(calc(-50% + 2rem), calc(-50% - 0.625rem));
    width: 1.875rem;
    height: 1.875rem;
    background: url('../icons/arrow-small-right.svg') no-repeat;
    background-size: contain;
}

.invoice-recap-block .manual {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31.25rem;
    border: 0.0625rem solid var(--black-15);
    border-radius: 0.75rem;
    margin-bottom: 1.25rem;
    padding: 0.625rem;
    font-weight: 500;
}

.invoices-recap-logos {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 1.875rem;
}

.bank-infos {
    position: fixed;
    bottom: 1.875rem;
    right: 1.875rem;
    display: flex;
}

.bank-infos .bank-infos-block {
    background-color: var(--white-100);
    border: 0.0625rem solid var(--blue-60);
    padding: 0.3125rem 0.625rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--blue-100);
}

.bank-infos .bank-infos-block.valid {
    border: 0.0625rem solid var(--green-100);
    color: var(--green-100);
}

.bank-infos .bank-infos-block.invalid {
    border: 0.0625rem solid var(--red-100);
    color: var(--red-100);
}

.bank-infos .bank-infos-block span {
    font-size: 24px;
    font-weight: bold;
}

.engine-data-container {
    width: 100%;
    background: var(--blue-5);
    padding: 1.25rem;
    border-radius: 0.75rem;
    margin-bottom: 1.875rem;
}

.engine-data-container .input-row-container:last-child {
    margin-bottom: 0;
}

.metas-file-chooser {
    background: var(--blue-5);
    height: 2.5rem;
    padding: 0 0.625rem;
    width: 100%;
    border-radius: 0.5rem;
    border: 0.0625rem dashed var(--blue-100);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.metas-file-chooser-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.metas-file-chooser.data {
    background: var(--green-30);
    border: 0.0625rem solid var(--green-170-40);
    cursor: pointer;
}

input[type='file'] {
    cursor: pointer;
}

.metas-file-chooser.data span {
    margin-top: 0;
    transition: all .5s ease-in-out;
}

.metas-file-chooser.data img {
    opacity: 0;
}

.metas-file-chooser.data .metas-file-chooser-input {
    opacity: 0;
}

.metas-file-chooser .metas-file-chooser-input {
    opacity: 1;
    transition: all .5s ease-in-out;
}

.metas-file-chooser input,
.customer-view-logo input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
}

.metas-file-chooser span {
    font-weight: 500;
    cursor: pointer;
    z-index: 99;
}

.importer-loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.875rem 0;
}

.importer-loader h4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0;
}

.importer-info {
    background: var(--blue-5);
    width: 100%;
    padding: 0.625rem 0.9375rem;
    border: 0.0625rem solid var(--blue-60);
    border-radius: 0.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
    margin-bottom: 0.625rem;
}

.importer-info.success {
    background: var(--green-30);
    border: 0.0625rem solid var(--green-100);
}

.importer-info.fail {
    background: var(--red-30);
    border: 0.0625rem solid var(--red-100);
}

.flex-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.importer-error-button-container {
    padding: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
}

.list-container.non-selectable .column-icon:first-child:before {
    left: 1.5rem;
}

.list-container.non-selectable tbody tr td:first-child {
    padding-left: 1.25rem;
}

.tabs-container .list-container.non-selectable tbody tr td:first-child {
    padding-left: 1.25rem;
}

.rows-by-page {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.main-button.download-button {
    height: 2rem;
    width: 2rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
    padding: 0;
    color: var(--black-50);
}

.main-button.download-button:hover {
    border: 0.0625rem solid var(--black-70);
}

.tabs-container .list-container.non-selectable .column-icon:first-child:before {
    left: 1.25rem;
}

.metas-invoices-list .list-container,
.debugger-list .list-container {
    margin: 1.25rem 0;
    height: unset;
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--black-5);
}

.error-span {
    font-weight: 800;
    color: var(--red-170);
}

.error-message {
    padding: 0.25rem 0.5rem;
    background-color: var(--red-30);
    border: 0.0625rem solid var(--red-100);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    width: fit-content;
}

.invoice-option-select {
    display: flex;
    flex-direction: column;
    background: var(--blue-20);
    color: var(--blue-120);
    border-radius: 0.5rem;
    margin-right: 0.625rem;
    padding: 0.625rem;
    width: 100%;
}

.invoice-option-select.exact-match {
    background: var(--green-30);
    color: var(--green-170);
}

.invoice-option-select-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice-option-select-infos div:first-child {
    display: flex;
    flex-direction: column;
}

.invoice-option-select-infos div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.invoice-option-select .invoice-number {
    font-weight: 700;
}

.invoice-option-select .invoice-customer {
    font-size: .8em;
    border-top: 0.0625rem solid var(--black-30);
    padding-top: 0.375rem;
    margin-top: 0.375rem;
}

.invoice-option-select.failed .invoice-customer {
    border-top: 0.0625rem solid var(--red-170);
}

.invoice-option-select .invoice-total,
.invoice-option-select .invoice-balance {
    font-weight: 700;
    font-size: 1.2em;
}

.transactions-list .transaction-block .MuiGrid-item {
    padding-top: 0.5rem;
}

.menu-link {
    text-decoration: none;
}

.float-right {
    float: right;
}

.MuiCircularProgress-svg {
    color: var(--blue-100);
}

.white-loader .MuiCircularProgress-svg {
    color: var(--white-100);
}

#root .MuiCircularProgress-root {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

body .MuiFormControl-root {
    width: 100%;
}

body .MuiInputBase-root,
body .MuiPaper-root {
    border-radius: 0.5rem;
}

.test-content {
    font-weight: 500;
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.file-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    margin-top: 1.25rem;
}

.test-content .test-duration {
    margin: 1.25rem 0;
    text-align: center;
    max-width: 40.625rem;
}

.test-reminder {
    margin: 1.25rem 0;
    text-align: center;
    max-width: 40.625rem;
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.file-drop {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.span-reminder {
    font-size: 12px;
    color: var(--red-100);
}

.test-done {
    margin: 3.125rem 0 1.25rem 0;
}

.test-content .test-form {
    max-width: 40.625rem;
    margin-top: 1.875rem;
}

.test-content a {
    display: flex;
    justify-content: center;
}

.test-list-download {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.icon-button {
    width: 1.5625rem;
    height: 1.5625rem;
    background-color: var(--black-5);
    border: 0.0625rem solid var(--black-10);
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    margin-inline: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.icon-button:hover {
    background-color: var(--black-10);
}

.test-list-download a,
.download-test {
    width: 1.5625rem;
    height: 1.5625rem;
    background-color: var(--green-30);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
}

.test-list-download a svg,
.download-test svg {
    color: var(--green-170);
}

.test-content .flex-50 {
    display: flex;
    align-items: center;
}

.test-content .flex-50 div {
    width: 100%;
}

.test-content .test-submit {
    width: 100%;
    margin-top: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.test-content .test-duration-details {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--green-30);
    border-radius: 0.5rem;
    padding: 1.25rem;
}

.counter-container {
    margin: 1.875rem 0;
    padding: 1.875rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--black-10);
    background: var(--blue-5);
    color: var(--blue-100);
}

.counter-container .starts-in {
    margin-bottom: 1.25rem;
    display: block;
    font-weight: 600;
    color: var(--black-100);
}

.counter-container-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.counter-block {
    margin: 0 0.9375rem;
}

.counter-block p {
    font-size: 3em;
    font-weight: bold;
    margin: 0;
}

.counter-block span {
    color: var(--black-100);
}

.counter-container .separator {
    font-size: 1.5em;
}

.portal-title span {
    color: var(--black-15);
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.debugger-container {
    display: flex;
}

.debugger-list .list-container {
    margin-top: 0;
}

.debugger-block {
    width: 100%;
}

.debugger-block:last-child {
    margin-left: 1.25rem;
}

.debugger-logo {
    height: 3.75rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.project-container {
    padding: 0 1.25rem 1.25rem 1.25rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.25rem;
}

.project-detail {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.project-detail .project-icon {
    width: 1.5625rem;
    height: 1.5625rem;
    border-radius: 0.5rem;
}

.project-detail span {
    font-weight: bold;
}

.view-container-header {
    display: flex;
    min-height: 3rem;
    height: fit-content;
    padding: 0.625rem 0.75rem;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
    flex-wrap: wrap;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
    background-color: var(--black-5);
}

.view-container-header.view-flexStart {
    justify-content: flex-start;
}

.view-container__content {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.view-container__content strong {
    white-space: nowrap;
}

.view-container-header-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: .75rem;
}

.view-container-content {
    display: grid;
    grid-template-columns: 18.75rem 1fr;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--black-10);
    background-color: var(--white-100);
}

.view-container-content-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-right: var(--black-10);
    padding: 1.25rem;
}

.task-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px 10px 0 0;
}

.task-item {
    display: flex;
    height: 42px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--black-10);
    background-color: var(--black-5);
    width: 100%;
}

.task-item-text {
    display: flex;
    align-items: center;
    gap: .375rem;
    font-size: 0.875rem;
    font-weight: 600;
}

.project-tasks {
    border-left: 1px solid var(--black-10);
    padding: 1.25rem;
}

.project-tasks p {
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 0 15px;
}

.project-tasks-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid var(--black-10);
    height: calc(100% - 40px);
    overflow: hidden;
}

.project-task-item {
    display: flex;
    min-height: 3.75rem;
    padding: 10px 15px;
    align-items: center;
    align-self: stretch;
    gap: 30px;
    border-bottom: 1px solid var(--black-10);
    background-color: var(--white-100);
}

.tasks-time {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    text-wrap: nowrap;
    width: 12.5rem;
}

.tasks-time label {
    font-family: Geomanist, serif;
    font-size: 15px;
    font-weight: 400;
}

.tasks-time input {
    width: 7.5rem;
}

.project-card {
    border: 0.0625rem solid var(--black-5);
    padding: 1.25rem;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: all .2s;
}

.project-card:hover {
    background: var(--black-5);
    border: 0.0625rem solid var(--black-15);
}

.project-view .project-card,
.project-view .project-card:hover {
    padding: 0;
    border: 0;
    background: unset;
    cursor: auto;
}

a .project-card {
    text-decoration: none;
    color: var(--black-100);
    height: 100%;
}

.project-card:hover .project-card-header {
    border-bottom: 0.0625rem solid var(--black-15);
}

.project-card-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;
}

.project-totals-card {
    display: flex;
    gap: 0.3125rem;
    align-items: baseline;
    border-radius: 0.75rem;
    padding: 0.3125rem 0.625rem;
    font-weight: 500;
    color: var(--blue-120);
    background: var(--blue-5);
    border: 0.0625rem solid var(--blue-40);
}

.project-totals-card .title {
    font-weight: 600;
    font-size: 22px;
}

.project-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    padding-bottom: 0.625rem;
    border-bottom: 0.0625rem solid var(--black-5);
    transition: all .2s;
}

.project-card-title {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.project-card-title h1 {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
}

.header-title {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.project-card .custom-tag,
.support-tooltip-title .custom-tag {
    padding: 0.0625rem 0.375rem;
    font-size: .9em;
}

.total-cost-badge {
    position: absolute;
    right: 0.25rem;
    z-index: 99;
    color: var(--blue-120);
    padding: 0 0.25rem;
    border-radius: 1.25rem;
    height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .9em;
    font-weight: 600;
}

.project-budget {
    display: flex;
    align-items: center;
}

.tab-header-inventaire {
    display: flex;
    gap: 0.625rem;
}

.filters-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.filters-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 1;
    font-family: var(--primary-font), serif;
    font-size: 12px;
}

.filters-buttons .dropdown-button,
.filters-buttons .filter-button {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    color: var(--black-60);
    font-size: 12px;
}

.filters-buttons .dropdown-button {
    padding: 0.3125rem 0.75rem;
    border-radius: 3.125rem;
    border: 0.0625rem solid var(--black-10);
}

.filters-buttons .dropdown-button:hover {
    background-color: var(--blue-5);
    border-color: var(--blue-30);
    color: var(--blue-100);
}

.members-menu .MuiMenu-paper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0.625rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    height: 300px;
    border: 0.0625rem solid var(--black-10);
    background-color: var(--white-100);
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.05);
}

.members-menu .MuiMenu-paper .MuiFormControl-root {
    margin-block-end: 0.625rem;
}

.member {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    padding: 8px !important;
    font-weight: bold !important;
    transition: all 0.3s ease-in-out;
    border-radius: 0.3125rem !important;
}

.member:hover {
    background-color: var(--black-10) !important;
}

.member-icon {
    display: flex;
    width: 1.5625rem;
    height: 1.5625rem;
    justify-content: center;
    align-items: center;
    border-radius: 2.75rem;
    box-shadow: 0 0.11rem 1.1rem 0 rgba(0, 0, 0, 0.05);
    font-size: 0.625rem;
    font-weight: bold;
}

.member-icon.large {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
}

.member span {
    text-transform: uppercase;
}

.members-container {
    display: flex;
    align-items: center;
}

.filters-buttons .filter-button {
    height: 1.6875rem;
    padding: 0 0.625rem;
    background-color: transparent;
    border: none;
    text-wrap: nowrap;
}

.filters-buttons .filter-button:hover {
    color: var(--blue-100);
    background-color: var(--blue-5);
    border-radius: 0.25rem;
}

.filters-wrapper .MuiFormControl-root {
    width: 250px !important;
}

.tabs-container {
    /* border: 0.0625rem solid var(--black-5); */
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.tabs-container .MuiTabs-root {
    border: 0.0625rem solid var(--black-10);
    border-radius: 0.625rem;
    width: 100%;
}

.tabs-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
}

.tab-panel-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.tab-panel-header h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.tab-panel-header span {
    font-size: 14px;
    color: var(--black-50);
}

.tab-panel-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* hr {
    width: 100%;
    border-top: 0.0625rem solid var(--black-10);
    opacity: 1;
    margin: 8px 0;
} */

.tabs-consommees-wrapper {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.tabs-consommees-texte {
    font-weight: bold;
    color: var(--black-70);
}

.project-card .budget-progress {
    height: 1.375rem;
    border-radius: 0.375rem;
    background-color: transparent;
    border: 0.0625rem solid var(--blue-40);
}

.budget-container .progress-bar-container.warning .budget-progress {
    border: 0.0625rem solid var(--orange-30);
}

.budget-container .progress-bar-container.warning .MuiLinearProgress-bar {
    background-color: var(--orange-30);
}

.budget-container .progress-bar-container.warning .total-cost-badge {
    color: var(--orange-170);
}

.project-card .budget-danger .budget-progress,
.budget-container .progress-bar-container.danger .budget-progress {
    background-color: var(--orange-30);
    border: unset;
}

.project-card .budget-danger .budget-progress .MuiLinearProgress-bar,
.budget-container .progress-bar-container.danger .MuiLinearProgress-bar {
    background-color: var(--orange-30);
}

.budget-danger .total-cost-badge,
.budget-container .progress-bar-container.danger .total-cost-badge {
    color: var(--orange-170);
}

.progress-bar-container.budget-warning .budget-progress .MuiLinearProgress-bar {
    background-color: var(--orange-30);
}

.progress-bar-container.budget-warning .budget-progress {
    border: 0.0625rem solid var(--orange-30);
}

.progress-bar-container.budget-warning .total-cost-badge {
    color: var(--orange-170);
}

.project-card .budget-progress .MuiLinearProgress-bar {
    background-color: var(--blue-30);
}

.project-card .budget-badge {
    background-color: var(--blue-20);
    color: var(--blue-120);
    font-size: .9em;
    font-weight: 600;
    margin-left: 0.625rem;
    position: relative;
    padding: 0 0.25rem;
    border-radius: 0.375rem;
    z-index: 9;
    white-space: nowrap;
    height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-card .budget-badge:after {
    content: '';
    position: absolute;
    background-color: var(--blue-20);
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(45deg);
    left: -0.25rem;
    z-index: -1;
    top: calc(50% - 0.25rem);
}

.progress-bar-container {
    width: 100%;
    position: relative;
}

.budget-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.internal-cost {
    font-weight: 500;
    color: var(--blue-120);
    text-align: right;
}

.header-title .custom-tag {
    margin-top: 0.375rem;
    padding: 0 0.375rem;
}

.main-button.return-button {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    aspect-ratio: 1/1;
    border: 0.0625rem solid var(--black-10);
}

.cta-button {
    display: flex;
    width: 1.875rem;
    height: 1.875rem;
    padding: 0;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    transition: background-color 0.2s, border-color 0.2s;
}

.cta-button.add-button {
    color: var(--blue-100);
    border: 1px solid var(--blue-400);
    background-color: var(--white-100);
}

.cta-button.add-button:hover {
    background-color: var(--blue-10);
    border-color: var(--blue-100);
}

.cta-button.delete-button {
    color: var(--red-500);
    border: 1px solid var(--red-30);
    background-color: var(--red-50);
}

.cta-button.delete-button:hover {
    background-color: var(--red-10);
    border-color: var(--red-500);
}

.phosphor-button {
    border: none;
    width: 1rem;
    height: 1rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}


.categories-data-container .inactive {
    opacity: 0.5;
    color: var(--black-60);
}

.categories-data-container .inactive .progress-bar-container .budget-progress {
    border: 0.0625rem solid var(--black-30);
}

.categories-data-container .inactive .progress-bar-container .total-cost-badge {
    color: var(--black-60);
}

.categories-data-container .category-title {
    font-weight: 500;
    display: flex;
}

.categories-data-container .inactive .category-title {
    font-weight: 400;
}

.project-card .no-cost {
    font-size: .9em;
    font-weight: 500;
    padding-top: 0.625rem;
    display: block;
}

.support-hours-container {
    border: 0.0625rem solid var(--black-10);
    padding: 1.25rem;
    border-radius: 0.5rem;
}

.support-hours-container .recharts-text.recharts-label {
    color: var(--white-100);
}

.project-card .internal-totals {
    margin-top: 0.625rem;
    padding-top: 0.625rem;
    border-top: 0.0625rem solid var(--black-5);
    transition: all .2s;
}

.margin-bottom {
    margin-bottom: 1.25rem;
}

.tabs-container .view-title h1 {
    font-size: 1.6em;
    font-weight: 700;
}

.header-info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    padding: 0 0.625rem;
    border-radius: 0.75rem;
    font-weight: 500;
    font-style: italic;
    color: var(--black-30);
}

.flex-contained {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.tab-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.tab-container .input-row-container:last-child,
.tab-container .waiting-order-warning:last-child {
    margin-bottom: 0;
}

.tabs-container .list-container {
    height: auto;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
}

.list-container.border {
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
}

.total-amount-wrapper {
    border-top: 0.0625rem solid var(--black-10);
    padding: 0.625rem 1.25rem;
}

.total-amount {
    font-weight: 600;
    color: var(--blue-120);
    background: var(--blue-20);
    padding: 0.25rem 0.5625rem;
    width: fit-content;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.MuiButtonBase-root.MuiTab-root {
    font-weight: bold;
    font-size: 14px;
    font-family: var(--primary-font), serif;
}

.chatbot-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.chatbot-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.chatbot-row h3,
h4 {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
}

.chatbot-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.9375rem;
}

.chatbot-button {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.3125rem;
    border-radius: 0.625rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.extract {
    border: 0.0625rem solid var(--Primary-Blue-200, #CDE6F9);
    background: var(--Primary-Blue-50, #F1F8FD);
    color: var(--Light-Blue-600, #2178AF);
}

.download {
    border: 0.0625rem solid var(--Grey-100, #DDD);
    background-color: var(--white-100);
    color: var(--Black-500, #0D0D0D);
}

.chatbot-data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-5);
    overflow: hidden;
}

.chatbot-data-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.625rem 1.25rem;
    background: var(--blue-20);
    border-bottom: 0.0625rem solid var(--black-5);
}

.chatbot-data-header h4 {
    font-size: 1.125rem;
    font-weight: 600;
}

.chatbot-data-content {
    display: flex;
    padding: 1.25rem;
    align-items: flex-start;
    gap: 0.9375rem;
    max-height: 50vh;
    overflow-y: scroll;
}

.project-warning-estimation {
    background: var(--red-30);
    padding: 0.375rem 0.5rem;
    color: var(--red-170);
    font-weight: 600;
    border-radius: 0.375rem;
    margin-bottom: 0.625rem;
    line-height: 1.2;
}

.roles-module {
    border: 0.125rem dashed var(--black-15);
    border-radius: 0.75rem;
    height: 100%;
}

.roles-submodule {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
}

.roles-module-title,
.roles-submodule-input {
    display: flex;
    align-items: center;
}

.grid-container {
    width: 100%;
}

.roles-module-title span {
    font-weight: 700;
}

.project-view .project-warning-estimation {
    margin-bottom: 1.25rem;
}

.project-card:hover .internal-totals {
    border-top: 0.0625rem solid var(--black-15);
}

@media (max-width: 50.625rem) {

    .portal-header {
        padding: 0 1.25rem;
    }

    .portal-title h1 {
        font-size: 1.2em;
    }

    .portal-title span,
    .portal-phone {
        display: none;
    }
}

.view-container.engine-config-view {
    padding-bottom: 0;
    border-bottom: 0.0625rem solid var(--black-5);
}

.view-container.engine-config-view:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.customer-view-header {
    display: flex;
    justify-content: space-between;
}

.customer-view-header h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
}

.customer-view-header .customer-view-logo {
    max-width: 12.5rem;
    height: 100%;
    max-height: 150px;
    border: 0.0625rem solid var(--black-5);
    border-radius: 0.75rem;
    padding: 1rem;
    position: relative;
    cursor: pointer;
}

.customer-view-header .customer-view-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    border-radius: 0.375rem;
}

.customer-view-data {
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
}

.customer-view-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.customer-view-title {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.customer-view-title span {
    color: var(--black-50);
    font-weight: 600;
}

.customer-view-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
}

.customer-view-details.custom-tag {
    text-transform: uppercase;
}

.customer-view-logo:after {
    content: 'Modifier';
    color: var(--white-100);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 0.625rem);
    height: calc(100% - 0.625rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    background: #cdcdcde8;
    opacity: 0;
}

.customer-view-logo:hover:after {
    opacity: 1;
}

.navbar-customer-icon,
.contact-list-img {
    width: 5.625rem;
    height: 3.125rem;
    border: 0.0625rem solid var(--black-5);
    border-radius: 0.75rem;
    padding: 0.625rem;
    position: relative;
    cursor: pointer;
    background-color: var(--white-100);
}

.contact-list-img {
    margin: 0.3125rem 0;
    height: 2.5rem;
    width: 5rem;
    min-width: 5rem;
    border-radius: 0.5rem;
}

.navbar-customer-icon img,
.contact-list-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    border-radius: 0.375rem;
}

.navbar-option-container .custom-tag {
    padding: 0 0.375rem;
    font-size: .9em;
}

.navbar-divider {
    height: 0.0625rem;
    width: 100%;
    background: var(--black-5);
}

.navbar-tags {
    display: flex;
    align-items: center;
}

.contact-list-customer-name {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.contact-list-customer-name span {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.contact-dot {
    padding-left: 1rem;
    position: relative;
}


.Mui-selected {
    color: var(--black-100) !important;
}

.MuiTabs-indicator {
    background-color: var(--blue-100) !important;
}

.week-tabs .MuiTabs-indicator {
    background-color: var(--black-30) !important;
}

.contact-dot:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.3125rem;
    background: var(--black-15);
}

.contact-dot.inactive:before,
.contact-dot.no-assistance:before,
.contact-dot.essential:before {
    background: var(--red-100);
}

.contact-dot.active:before,
.contact-dot.premium:before {
    background: var(--green-100);
}

.contact-dot.new:before {
    background: var(--blue-100);
}

.holidays-calendar-container {
    width: 20rem;
    border: 0.0625rem solid var(--black-5);
    border-radius: 0.75rem;
    margin-bottom: 1.25rem;
}

.MuiPickersDay-root.highlighted-day {
    font-weight: 600;
    color: var(--white-100);
}

.MuiPickersDay-root.highlighted-day.half-day:before {
    content: '';
    width: 2.25rem;
    height: 1.125rem;
    background: var(--black-100);
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0 0 1.125rem 1.125rem;
    transform: rotate(-45deg);
    transform-origin: top;
    z-index: -1;
}

.MuiPickersDay-root.highlighted-day.accepted.half-day {
    background: rgba(47, 177, 99, 0.82);
}

.MuiPickersDay-root.highlighted-day.waiting.half-day {
    background: #f36e1bcc;
}

.MuiPickersDay-root.highlighted-day.waiting.half-day:before {
    background: var(--orange-170);
}

.MuiPickersDay-root.highlighted-day.accepted {
    background: var(--green-100);
}

.MuiPickersDay-root.highlighted-day.unpaid {
    background: var(--red-100);
}


.MuiPickersDay-root.highlighted-day.waiting {
    background: var(--orange-100);
}

.holidays-recap-container {
    border: 0.0625rem solid var(--black-5);
    padding: 1.25rem;
    border-radius: 0.75rem;
    margin: 0 0 1.25rem 1.25rem;
    width: 100%;
}

.holiday-add-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.export-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.export-block h2 {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.full-width {
    width: 100%;
}

.d-flex-row {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.d-flex-col {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.chatbot-code-data {
    background: var(--black-5);
    border: 0.0625rem solid var(--black-10);
    border-radius: 0.75rem;
    padding: 0.625rem;
    font-size: .9em;
    margin-top: 1.25rem;
    position: relative;
}

.chatbot-code-data .download-button {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
}

.header-subtitle {
    font-size: 1.2em;
    font-weight: 700;
    padding-left: 1.875rem;
    position: relative;
    margin-bottom: 1.25rem;
}

.header-subtitle:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 1.375rem;
    height: 1.375rem;
    background: url('../icons/arrow-small-right--blue.svg') no-repeat;
    background-size: contain;
}

.translate-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    gap: 0.625rem;
}

.translate-container .select__value-container {
    padding-left: 0.5rem;
}

.translate-container .single-search-input {
    min-width: 12.5rem;
    width: 12.5rem;
}

.infos-container-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.infos-container-wrapper.is-white {
    border: 0.0625rem solid var(--black-10);
    border-radius: 0.625rem;
    overflow: hidden;
}

.infos-content-wrapper {
    display: flex;
    padding: 1.875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    flex: 1 0 0;
    align-self: stretch;
    border-right: 0.0625rem solid var(--black-10);
    border-bottom: 0.0625rem solid var(--black-10);
}

.infos-title-wrapper {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex-wrap: wrap;
    position: relative;
    padding-left: 1.875rem;
}

.infos-title-wrapper:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem;
    height: 1.25rem;
    background: url('../icons/folder-open--blue.svg') no-repeat;
    background-size: contain;
}

.infos-title-wrapper.info:before {
    background: url('../icons/info--altelis.svg') no-repeat;
    background-size: contain;
}

.infos-title-wrapper.contract:before {
    background: url('../icons/document-text--blue.svg') no-repeat;
    background-size: contain;
}

.infos-title-wrapper.config:before {
    background: url('../icons/gear-six--blue.svg') no-repeat;
    background-size: contain;
}

.infos-title-wrapper.link:before {
    background: url('../icons/link-alt--altelis.svg') no-repeat;
    background-size: contain;
}

.infos-title-wrapper.hebergement:before {
    background: url('../icons/cloud--altelis.svg') no-repeat;
    background-size: contain;
}

.infos-title-wrapper.date:before {
    background: url('../icons/calendar--altelis.svg') no-repeat;
    background-size: contain;
}

.infos-title-wrapper p {
    color: var(--black-100);
    text-align: center;
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0;
}

.infos-container {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex-wrap: wrap;
    position: relative;
    padding-left: 1.5625rem;
}

.infos-container:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.9375rem;
    height: 0.9375rem;
    background: url('../icons/folder-open--blue.svg') no-repeat;
    background-size: contain;
}

.infos-container.mel:before {
    background: url('../icons/wrench--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.cms:before {
    background: url('../icons/cms--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.builder:before {
    background: url('../icons/builder-used--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.version:before {
    background: url('../icons/version-cms--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.plugins:before {
    background: url('../icons/plugins--gray.svg') no-repeat;
    background-size: contain;
    top: 0.3125rem;
    transform: initial;
}

.infos-container.traduction:before {
    background: url('../icons/traduction--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.languages:before {
    background: url('../icons/languages--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.hebergement:before {
    background: url('../icons/hebergement--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.gear-six:before {
    background: url('../icons/gear-six--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.messagerie:before {
    background: url('../icons/messagerie--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.adresse-mail:before {
    background: url('../icons/email--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.moteur:before {
    background: url('../icons/calendar--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.lien-moteur:before {
    background: url('../icons/link-alt--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.comparateur:before {
    background: url('../icons/graphique--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.api:before {
    background: url('../icons/api--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.google-ads:before {
    background: url('../icons/google-ads--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.google-analytics:before {
    background: url('../icons/google-analytics--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.metas:before {
    background: url('../icons/graphique--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.cloud-arrow-up:before {
    background: url('../icons/cloud-arrow-up--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container.hourglass:before {
    background: url('../icons/hourglass--gray.svg') no-repeat;
    background-size: contain;
}

.infos-container-contract-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.infos-container-contract {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.1875rem;
    padding-inline: 0.5rem;
    gap: 0.25rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid var(--black-10);
    background-color: var(--white-100);
    color: var(--black-100);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

.infos-container-contract:hover {
    background-color: var(--black-10);
}

.infos-container-title {
    color: var(--black-70);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.conso-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.3125rem;
}

.red-background {
    color: var(--red-170);
    background-color: var(--red-30);
}

.green-background {
    color: var(--green-170);
    background-color: var(--green-30);
}

.table-fixed {
    width: 100%;
    table-layout: fixed;
}

.col-id {
    width: 5% !important;
}

.col-nom {
    width: 25% !important;
}

.col-statut {
    width: 15%;
}

.col-support {
    width: 20%;
}

.col-conso {
    width: 20%;
}

.col-site {
    width: 10%;
}

.contact__wrapper {
    display: flex;
    padding: 1.25rem;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.625rem;
    height: 100%;
    border-radius: 0.625rem;
    background-color: var(--black-5);
    border: 0.0625rem solid var(--black-10);
    align-items: flex-start;
}

.contact__titre,
.contact__info {
    margin: 0;
}

.contact__titre {
    color: var(--black-100);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.contact__info {
    color: var(--black-70);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    display: flex;
    gap: 0.625rem;
    align-items: center;
    white-space: nowrap;
    position: relative;
    padding-left: 1.375rem;
}

.contact__info--email:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.9375rem;
    height: 0.9375rem;
    background: url('../icons/email--gray.svg') no-repeat;
    background-size: contain;
}

.contact__info--tel:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.9375rem;
    height: 0.9375rem;
    background: url('../icons/phone--gray.svg') no-repeat;
    background-size: contain;
}

.contact__info span {
    display: flex;
    padding: 0.25rem 0.625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: var(--Light-Blue-50, #EEF4FC);
    color: #2178AF;
}

/* Block: contacts */
.contacts__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contacts__contact {
    display: flex;
    padding: 1.25rem 1.875rem;
    gap: 1.25rem;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: var(--black-10);
    width: 100%;
}

.contacts__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.contacts__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;
    width: 100%;
}

.contacts__button {
    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
}

.contacts__button button {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.3125rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
    background-color: var(--white-100);
}

#project-form {
    display: grid;
    grid-template-columns: [content] 2fr [sidebar] 350px;
    border-top: 0.0625rem solid var(--black-10);
}

.project-form-section {
    grid-area: content;
    display: flex;
    padding: 1.375rem 1.25rem 1.25rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    border-right: 0.0625rem solid var(--black-10);
    height: 72vh;
    overflow-y: scroll;
}

.project-form-section::-webkit-scrollbar {
    display: none;
}

.project-form-section {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.project-form-aside {
    grid-area: sidebar;
    display: flex;
    padding: 1.25rem 1.25rem 0 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.9375rem;
}

.project-form-article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}

.project-form-article {
    display: flex;
    padding: 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
    border-radius: 0.5rem;
    background-color: var(--black-5);
    border: 0.0625rem solid var(--black-10);
}

.form-group-full {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 1.25rem;
}

.form-group-column {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 0.5rem;
}

.form-group-full label,
.form-group-column label {
    text-wrap: nowrap;
}

/* .react-select-container {
    width: 100% !important;
} */

.react-select__control {
    width: 100% !important;
}

.project-form-article .project-form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.project-form-header-title {
    display: flex;
    padding-inline-start: 0.625rem;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.project-form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.project-form-header-title h2 {
    font-size: 1.0625rem;
    font-weight: 600;
    margin: 0;
}

.project-form-header-title svg {
    color: var(--blue-100);
}

.full-width {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3125rem;
    color: var(--black-70);
}

.full-width input {
    transition: all 0.3s ease-in-out;
}

.full-width label {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.full-width.aside {
    display: flex;
    padding: 0.9375rem 0.625rem 0.625rem 0.625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.9375rem;
    align-self: stretch;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--black-10);
    background-color: var(--white-100);
}

.full-width.aside.row-input {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
}

.full-width.row-input {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
}

.full-width.contract-upload {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.full-width.aside label {
    color: var(--black-100);
    font-weight: 600;
}

.logo-upload {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.logo-preview {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    border: 0.0625rem solid var(--black-10);
    overflow: hidden;
    position: relative;
}

.logo-preview img {
    width: 2.5rem;
    height: 2.5rem;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.logo-preview-wrapper {
    position: relative;
    display: inline-block;
}

.logo-remove-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-100);
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
    backdrop-filter: blur(0.3125rem);
}

.logo-preview:hover .logo-remove-overlay {
    opacity: 1;
}

.basic-select,
.basic-multi-select {
    width: 100%;
}

.basic-select.fit {
    width: fit-content;
}

.logo-dropzone {
    display: flex;
    padding: 1.25rem 0.625rem;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem;
    align-self: stretch;
    border-radius: 0.3125rem;
    background-color: var(--black-5);
    border: 0.0625rem solid var(--black-10);
    cursor: pointer;
}

.logo-dropzone svg {
    color: var(--black-70);
}

.logo-dropzone p {
    text-align: center;
    margin: 0;
    color: var(--Grey-500, #868686);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.0081rem;
}

.contract-details-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.contract-details {
    display: flex;
    height: 2.5rem;
    padding: 0.625rem;
    align-items: center;
    gap: 0.3125rem;
    align-self: stretch;
    border-radius: 0.5rem;
    background-color: var(--black-5);
}

.contract-details p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: var(--Black-400, #252525);
    font-family: Geomanist, serif;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0;
}

.contract-details svg {
    color: var(--blue-100);
}

.contract-details button {
    display: flex;
    width: 1.5625rem;
    height: 1.5625rem;
    padding: 0.3125rem;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    gap: 0.4018rem;
    border-radius: 0.3929rem;
    border: 0.0625rem solid var(--black-10);
    background: rgba(255, 255, 255, 0.50);
}

.select__control {
    cursor: pointer !important;
    border-radius: 0.5rem !important;
    background-color: var(--white-100) !important;
    border-color: var(--black-10) !important;
}

.select__multi-value {
    border-radius: 0.4375rem !important;
    background: var(--black-10) !important;
}

.select__single-value {
    font-size: 0.875rem !important;
}

.select__multi-value__label,
.select__single-value {
    font-weight: bold !important;
}

.full-width input:hover,
.full-width input:focus,
.select__multi-value:hover,
.select__multi-value:focus,
.select__single-value:hover,
.select__single-value:focus,
.basic-select:hover,
.basic-select:focus {
    background-color: var(--white-100) !important;
    border-color: var(--blue-20) !important;
}

.Toastify__toast-body {
    font-family: var(--primary-font), serif;
}

.tracking-hours-remaining-container {
    background: var(--black-5);
    border: 1px solid var(--black-10);
    border-radius: 0.5rem;
    padding: 1rem 1.25rem;
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin-bottom: 1.25rem;
}

.tracking-hours-remaining-value {
    background: var(--white-100);
    padding: 0.25rem 0.5625rem;
    border-radius: 0.5rem;
    border: 1px solid var(--black-10);
    font-weight: 600;
}

.custom-tracking-table-container {
    border-radius: 0.625rem;
    border: 1px solid var(--black-10);
    margin-bottom: 1.25rem;
    white-space: nowrap;
    overflow-x: auto;
}

.custom-tracking-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-tracking-table thead {
    background-color: var(--blue-5);
}

.custom-tracking-table th,
.custom-tracking-table td {
    padding: 0.75rem .4rem;
    text-align: left;
    border-bottom: 1px solid var(--black-10);
}

.custom-tracking-table th {
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--black-70);
    padding-left: 30px;
    position: relative;
    cursor: pointer;
}

.custom-tracking-table th:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background: url('../icons/folder-open--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tracking-table th.date:before {
    background: url('../icons/calendar--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tracking-table th.project:before {
    background: url('../icons/folder-open--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tracking-table th.task:before {
    background: url('../icons/tag-simple--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tracking-table th.comments:before {
    background: url('../icons/chat--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tracking-table th.duration:before {
    background: url('../icons/clock--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tracking-table th.actions:before {
    background: url('../icons/gear-six--gray.svg') no-repeat;
    background-size: contain;
}

.entries-totals td {
    border-top: 1px solid var(--black-10);
    border-bottom: 0;
    background: var(--black-5);
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--black-70);
}

.custom-tracking-table .single-search-input,
.custom-tracking-table .comment-input {
    min-width: 200px;
}

.custom-tracking-table .entry-line-date .custom-tag {
    margin-left: .5rem;
}

.task-option-container {
    background: var(--black-5);
    padding: 0 6px 6px 6px;
    font-size: 14px;
    font-weight: 600;
}

.task-option-container:first-child {
    padding-top: 6px;
}

.task-option-data {
    background: var(--white-100);
    padding: 6px;
    border: 1px solid var(--black-10);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 6px;
    transition: all 0.3s ease;
}

.task-option-data:hover {
    border: 1px solid var(--blue-20);
}

.subtask-option {
    display: flex;
    flex-direction: column;
}

.searched-subtask {
    padding-left: 24px;
    position: relative;
}

.subtask-parent {
    position: relative;
    font-size: .7rem;
    color: var(--black-70);
}

.main-task-option {
    position: relative;
    padding: 4px 6px 4px 24px;
    background: var(--blue-10);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.custom-tracking-table .custom-tag {
    background: var(--black-5);
    color: var(--black-100);
    font-weight: 500;
}

.custom-tracking-table tr {
    font-size: .9rem;
    font-weight: 500;
}

.task-info {
    font-size: .8rem;
}

.main-task-option:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: url('../icons/arrow-right--blue.svg') no-repeat;
    background-size: contain;
}

.searched-subtask:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background: url('../icons/arrow-bend-down-right--black.svg') no-repeat;
    background-size: contain;
}

.custom-dropdown-button {
    display: flex;
    align-items: center;
}

.custom-tracking-table .column-icon:before {
    content: '';
    display: inline-block;
    margin-right: 0.5rem;
}

.custom-tracking-table .single-search-input .select__value-container,
.modal-new-model .single-search-input .select__value-container {
    padding-left: 0.4rem;
}

.tooltip-models-container {
    background: var(--white-100);
}

#models-tooltip .MuiTooltip-tooltip {
    background: var(--white-100);
    border: 1px solid var(--black-10);
    margin-top: 10px;
    border-radius: .5rem;
    color: var(--black-100);
    padding: 8px;
    font-size: .9rem;
    font-family: var(--primary-font), sans-serif;
    font-weight: 500;
    max-width: unset;
}

.tooltip-models-container {
    display: flex;
    flex-direction: column;
}

.tooltip-models-container .new-model {
    color: var(--blue-100);
    padding: 2px 6px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
    transition: all .3s ease;
    border-radius: 6px;
}

.tooltip-models-container .new-model:hover {
    background: var(--blue-5);
}

.main-button.dropdown-left {
    border-radius: 0.5rem 0 0 0.5rem;
}

.dropdown-right {
    background: var(--blue-100);
    height: 2.375rem;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0.5rem 0.5rem 0;
    border-left: 1px solid var(--blue-170);
    cursor: pointer;
}

.dropdown-right:hover {
    background: var(--blue-500);
}

.tracking .MuiTab-root {
    min-height: 48px;
}

td.entry-line-project,
td.entry-line-task {
    min-width: 300px;
    width: 300px;
    font-weight: bold;
}

td.entry-line-actions {
    min-width: 100px;
    width: 100px;
}

td.entry-line-duration {
    min-width: 80px;
    width: 80px;
    max-width: 80px;
}

td.entry-line-comments {
    font-weight: bold;
}

td.entry-line-date {
    min-width: 100px;
    width: 100px;
}

td.entry-line-date.editable {
    min-width: 170px;
    width: 170px;
}

.custom-tracking-table .classic-input.number-input {
    width: 100%;
}

.custom-tracking-table .single-search-input .select__single-value {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    border-radius: .3rem;
}

.custom-tracking-table .entries-totals .entries-count {
    padding-left: 16px;
}

.custom-tracking-table .new-entry {
    padding-left: 8px;
}

.custom-tracking-table .select__menu {
    background: var(--black-5);
}

body .select__menu-list {
    padding: 0;
}

.task-option-section {
    display: flex;
    align-items: center;
    gap: 2px;
}

.task-option-section svg {
    min-width: 8px;
}

.task-option-data .section,
.task-option-data .subtask-parent {
    background: var(--black-5);
    font-size: .7rem;
    padding: 1px 4px;
    border-radius: 6px;
    font-weight: 500;
    color: var(--black-100);
}

.task-option-data .section {
    padding: 1px 4px 1px 14px;
    position: relative;
}

.task-option-data .section:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    background: var(--black-30);
    border-radius: 2px;
}

.task-option-data .subtask-parent {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.task-option-data:hover .main-task-option {
    background: var(--blue-100);
    color: white;
}

.task-option-data:hover .main-task-option:before {
    filter: brightness(0) invert(1);
}

.custom-tracking-table .select__indicator {
    padding: 8px 4px;
}

.entry-line-actions-buttons {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 10.519px;
    justify-content: center;
    align-items: center;
}

.modal-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.modal-new-model label {
    font-weight: 500;
    color: var(--black-70);
    font-size: .9rem;
    padding-left: 20px;
    margin-bottom: 8px;
    position: relative;
}

.modal-new-model label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background: url('../icons/google-ads--gray.svg') no-repeat;
    background-size: contain;
}

.modal-new-model label.model:before {
    background: url('../icons/copy-simple--gray.svg') no-repeat;
    background-size: contain;
}

.modal-new-model label.repeat:before {
    background: url('../icons/repeat--gray.svg') no-repeat;
    background-size: contain;
}

.modal-new-model label.name:before {
    background: url('../icons/text-aa--gray.svg') no-repeat;
    background-size: contain;
}

.modal-new-model label.project:before {
    background: url('../icons/folder-open--gray.svg') no-repeat;
    background-size: contain;
}

.modal-new-model label.task:before {
    background: url('../icons/tag-simple--gray.svg') no-repeat;
    background-size: contain;
}

.modal-new-model label.duration:before {
    background: url('../icons/clock--gray.svg') no-repeat;
    background-size: contain;
}

.modal-new-model label.comments:before {
    background: url('../icons/chat--gray.svg') no-repeat;
    background-size: contain;
}

body .select__menu-portal {
    z-index: 9999;
}

.tooltip-models-line {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: 2px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: all .3s ease;
}

.tooltip-models-line:hover {
    background: var(--blue-5);
    border: 1px solid var(--blue-10);
    color: var(--blue-100);
}

.tooltip-models-options-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.tooltip-models-options-container span {
    background: var(--blue-5);
    border: 1px solid var(--blue-10);
    padding: 4px 10px 4px 26px;
    border-radius: 6px;
    font-size: .8rem;
    font-weight: 600;
    color: var(--blue-100);
    position: relative;
    cursor: pointer;
    transition: all .3s ease;
}

.tooltip-models-options-container span.delete {
    background: var(--red-10);
    border: 1px solid var(--red-20);
    color: var(--red-500);
}

.tooltip-models-options-container span:hover {
    background: var(--blue-10);
    border: 1px solid var(--blue-20);
}

.tooltip-models-options-container span.delete:hover {
    background: var(--red-20);
    border: 1px solid var(--red-30);
}

.tooltip-models-options-container span:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background: url('../icons/google-ads--gray.svg') no-repeat;
    background-size: contain;
}

.models-options-button {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all .3s ease;
    border: 1px solid transparent;
    cursor: pointer;
}

.models-options-button:hover {
    border: 1px solid var(--blue-20);
    background: var(--white-100);
}

#models-tooltip .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementLeft {
    margin-top: 0;
    margin-right: 2px;
}

.tooltip-models-options-container span.edit:before {
    background: url('../icons/pencil--blue.svg') no-repeat;
    background-size: contain;
}

.tooltip-models-options-container span.duplicate:before {
    background: url('../icons/copy--blue.svg') no-repeat;
    background-size: contain;
}

.tooltip-models-options-container span.delete:before {
    background: url('../icons/trash--red.svg') no-repeat;
    background-size: contain;
}

.squared-button.automation {
    background: var(--blue-5);
    border: 1px solid var(--blue-10);
}

.squared-button.automation:hover {
    background: var(--blue-10);
    border: 1px solid var(--blue-20);
}

.squared-button.automation:before {
    display: none;
}

.automations-window-container {
    background: var(--white-100);
    position: absolute;
    border: 1px solid var(--black-5);
    right: 34px;
    top: 102px;
    padding: 0px 20px 20px 20px;
    border-radius: 8px;
    height: calc(100dvh - 124px);
    box-shadow: 0 0 30px 0 var(--black-10);
    z-index: 9;
    width: 25rem;
    transition: all .3s ease;
    overflow-y: scroll;
}

.automations-window-container.hidden {
    top: 50%;
    transform: translateY(-50%);
    right: -25rem;
    display: block;
}

.automations-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--black-10);
    padding-block: 20px;
    position: sticky;
    top: 0;
    background-color: var(--white-100);
    z-index: 3;
}

.automations-header h1 {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--black-100);
    margin: 0;
}

.close-button {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: var(--white-100);
    border: 1px solid var(--black-10);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.close-button:hover {
    background: var(--black-5);
    border: 1px solid var(--black-10);
}

.automations-window-container .blue-button {
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 3;
}

.repeat-every-select span {
    font-size: 0.8rem;
    font-weight: 600;
    background: var(--black-5);
    border-radius: 20px;
    padding: 4px 10px;
    border: 1px solid var(--black-10);
    cursor: pointer;
    transition: all .3s;
}

.repeat-every-select span:hover {
    background: var(--blue-5);
    border: 1px solid var(--blue-10);
    color: var(--blue-100);
}

.repeat-every-select span.selected {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
    color: var(--white-100);
}

.repeat-every-select label {
    margin-bottom: 0;
}

.flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

body .Toastify__toast-container {
    z-index: 99999;
}

.automation-line {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--black-10);
    padding: 18px;
    border-radius: 10px;
    transition: all .3s;
    margin-bottom: 10px;
    position: relative;
}

.automation-line.inactive::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: 0.5;
    pointer-events: none;
    z-index: 1;
}

.automation-line .MuiSwitch-root {
    position: relative;
    z-index: 2;
}

.automation-line:hover {
    background: var(--black-5);
}

.automation-line h2 {
    font-size: .9rem;
    font-weight: 600;
    color: var(--black-100);
    margin: 0;
}

.main-app .MuiSwitch-root {
    width: 42px;
    height: 22px;
    padding: 0;
}

.main-app .MuiSwitch-track {
    background-color: var(--red-100);
    border-radius: 20px;
    opacity: 1;
}

.main-app .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
    margin: 1px;
    background-color: white;
}

.main-app .MuiSwitch-root .Mui-checked+.MuiSwitch-track {
    background-color: var(--blue-100);
    opacity: 1;
}

.main-app .MuiSwitch-switchBase {
    padding: 2px;

    &.Mui-checked {
        transform: translateX(20px);
    }
}

.main-app .MuiSwitch-root .MuiSwitch-switchBase.Mui-focused {
    outline: none;
    box-shadow: none;
}

.main-app .MuiSwitch-root .MuiSwitch-switchBase.Mui-focused+.MuiSwitch-track {
    box-shadow: none;
}

.automation-line-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.automation-generated-line td span,
.automation-generated-line td label {
    opacity: .5;
}

.custom-tracking-table .new-entry {
    display: flex;
    align-items: center;
}

.custom-tracking-table .new-entry .new-line-button {
    border-radius: 0.5rem 0 0 0.5rem;
}

.custom-tracking-table .new-entry .dropdown-right {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    border-left: 0;
    transition: all .3s ease;
}

.custom-tracking-table .new-entry .dropdown-right:hover {
    background: var(--black-5);
}

.custom-tracking-table .new-entry .dropdown-right svg {
    fill: var(--blue-100);
}

.custom-filter-button {
    display: flex;
    padding: 5px 12px;
    align-items: center;
    gap: 5px;
    border-radius: 100px;
    border: 1px solid var(--Grey-100, #DDD);
    cursor: pointer;
    color: var(--black-70);
    font-family: Manrope;
    font-size: 12px;
    font-weight: bold;
    transition: all .3s ease;
}

.custom-filter-button:hover {
    border: 1px solid var(--Primary-Blue-200, #CDE6F9);
    background: var(--Primary-Blue-50, #F1F8FD);
    color: var(--blue-100);
}

.custom-time-entry-popup {
    width: max-content;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background: var(--blue-100);
    color: var(--white-100) !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--black-10) !important;
}

.main-button.cancel-button {
    border: 1px solid var(--red-10);
    color: var(--red-100);
}

.main-button.cancel-button:hover {
    background: var(--red-5);
    border: 1px solid var(--red-20);
}

.Toastify__toast-container {
    bottom: 0 !important;
}

.Toastify__toast-container {
    bottom: 0 !important;
}

.asana-blur-auth {
    position: absolute;
    backdrop-filter: blur(2px);
    top: 200px;
    right: 30px;
    width: calc(100% - 260px);
    height: calc(100vh - 220px);
    z-index: 999;
}

.week-tab-arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid var(--black-10);
    height: 100%;
    width: 2.5rem;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.week-tab-arrows:hover {
    background-color: var(--black-5);
}

.customer-dot {
    position: relative;
    padding-right: 16px;
}

.customer-dot:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background: var(--black-10);
    border-radius: 50%;
}

.customer-dot.active:before {
    background: var(--green-100);
}

.mensual-return-rate {
    margin-top: 1.25rem;
    font-size: .9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .625rem;
}

.mensual-return-rate .price {
    background: var(--blue-10);
    padding: 5px 10px;
    font-weight: 700;
    border-radius: .625rem;
    color: var(--blue-100);
    border: 1px solid var(--blue-20);
}

